import { menuData } from "../global/constants"
import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import { defaultImg, timestamp2date, gEventArea, getEventType } from '../global/constants'

import eventStartImg from '../assets/eventStart.png';
import eventJoinImg from '../assets/eventJoin.png';

import arrowrightwtImg from '../assets/arrowrightwt.png';




const EventView = (props) => {
  const { t } = useTranslation();

  console.log(props)
  const { i18n } = useTranslation();
  const i = props.data

  return <Link to={`/eventdetail?id=${i.id}`} className=" flex gap-2 sm:gap-4  border border-solid border-main  rounded-[10px] bg-white overflow-hidden mb-4 ">

    <div className=" relative w-[45%]  pl-4 py-4">
      <img className="w-full  object-cover aspect-[16/9] rounded-[10px] mb-2" src={i&&i.images.length> 0 ? i.images[0].url : defaultImg} />
      <div className="p-[6px] bg-[#8f92d1] text-sm rounded-[10px] text-center font-bold">
        {i.area.title} / {i.type.title}
      </div>
    </div>

    <div className=" relative w-[55%] min-w-[130px] py-4">
      <div className="flex flex-col  h-full">
        <div className="h-full">
          <p className="text-main3 font-bold text-sm mb-3 truncate">{i.title}</p>

          <div className="flex items-center mb-0.5">
            <img className="w-4 h-4 mr-1" src={eventJoinImg} />
            <p className="text-main font-bold text-sm ">參加截止時間</p>
          </div>
          <div className="  text-main3  text-sm mb-2">{timestamp2date(i.joinDeadline)}</div>

          <div className="flex items-center mb-0.5">
            <img className="w-4 h-4 mr-1" src={eventStartImg} />
            <p className="text-main font-bold text-sm ">活動開始時間</p>
          </div>
          <div className="  text-main3  text-sm ">{timestamp2date(i.startDate)}</div>


        </div>
        <div className="text-gray-500 flex justify-between text-sm">

          <div className="hidden flex items-center  ">
            <img className="w-4 h-4 mr-1" src={require('../assets/view.png')} />
            <p className="">{i.view}</p>
          </div>

        </div>
      </div>

    </div>
    <div className="bg-main flex min-h-full w-[30px] flex-none">
      <img className="w-4 h-4 m-auto" src={arrowrightwtImg} />

    </div>



  </Link>

}
export default EventView