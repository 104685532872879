
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import { getUserInfo, gUserInfo } from "../../global/constants"

import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";

import defaultIconImg from '../../assets/bg.jpg';

import joinEventIcon from '../../assets/event.png';
import editIcon from '../../assets/user/edit.png';
import receiptIcon from '../../assets/user/receipt.png';
import familyIcon from '../../assets/user/family.png';
import passbookIcon from '../../assets/user/passbook.png';
import aboustusIcon from '../../assets/user/aboutus.png';


import arrowrightImg from '../../assets/arrowright.png';

import copyImg from '../../assets/user/copy.png';
import donateImg from '../../assets/user/donate.png';
import transferImg from '../../assets/user/transfer.png';
import userInfoImg from '../../assets/user/userInfo.png';
import qrcodeImg from '../../assets/user/qrcode.png';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Swiper from '../../compontents/Swiper'
import { useSelector, useDispatch } from 'react-redux';

import QRCode from 'qrcode.react';




const UserInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userInfo = useSelector(state => state.userInfo);

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const [showQRcode, setShowQRcode] = useState(false);

  const funcList = [
    {
      title: '活動紀錄',
      path: '/eventrecord',
      img: joinEventIcon
    },
    {
      title: '時間存摺',
      path: '/passbookrecord',
      img: passbookIcon
    },
    {
      title: '兌換紀錄',
      path: '/giftrecord',
      img: receiptIcon
    },
    // {
    //   title: '分享二維碼',
    //   path: '/shareqrcode'
    // },
    {
      title: '個人資料',
      path: '/edituserinfo',
      img: editIcon
    },
    {
      title: '我的家人',
      path: '/myfamily',
      img: familyIcon
    },
    {
      title: '關於我們',
      path: '/aboutus',
      img: aboustusIcon
    },


  ]

  const toolList = [
    {
      title: '聯絡人',
      path: ''
    },
    {
      title: '常用地址',
      path: ''
    },
    {
      title: '常用需求',
      path: ''
    },
    {
      title: '需求管理',
      path: ''
    },
    {
      title: '設置',
      path: ''
    },
    {
      title: '切換長者版',
      path: ''
    },
  ]



  const [isFisrt, doneFisrt] = useState(false)

  function logout() {
    localStorage.removeItem('userID')
    localStorage.removeItem('accessToken')
    navigate('/login')

  }



  function copySystemId() {
    var str = document.getElementById('systemid');
    window.getSelection().selectAllChildren(str);
    document.execCommand("Copy")
    window.getSelection().removeAllRanges()
    toast('已複製系統ID')
  }


  useEffect(() => {
    console.log(userInfo)


  }, []);


  return <>
  <ToastContainer/>
    {
      userInfo != undefined ?

        <div className="appWidth pt-10 " >


          <div className="w-full mx-auto relative flex justify-center  items-center mb-8">

            <div className=" overflow-hidden shadow-md mr-4 sm:mr-10 flex-none relative w-28 h-28 bg-white rounded-full  mb-1">
              <div className="h-full ">
                <img className="w-full h-full object-cover" src={userInfo.images.length > 0 ? userInfo.images[0].url : defaultIconImg} />
              </div>

            </div>
            <div className=" ">
              <button onClick={() => copySystemId()} className=" mb-[6px] cursor-pointer rounded-[6px]  bg-main4 px-4 py-3 flex justify-between items-center gap-x-2">
                <p className="text-sm text-left text-white font-bold">系統 ID : <span id="systemid">{userInfo.systemId}</span></p>
                <img className="w-6 object-contain" src={copyImg} />
              </button>

              <div className="flex justify-start items-center gap-x-2 mb-[6px]">
                <p className="text-main4 text-md ">暱稱: {userInfo.nickName != null && userInfo.nickName != ""  ? userInfo.nickName : '暫無'}</p>
              </div>

              <button onClick={() => setShowQRcode(!showQRcode)} className="flex justify-start items-center gap-x-2">
                <p className="text-main4 text-md ">{!showQRcode?"顯示":"隱藏"}二維碼</p>
                <img className="w-6 object-contain" src={qrcodeImg} />
              </button>

            </div>


          </div>

         {
           showQRcode ?
          <QRCode
            id="qrCode"
            value={userInfo.systemId}
            size={200} 
            fgColor="#000000" 
            style={{ margin: 'auto' }}
            imageSettings={{ 
              src: 'logoUrl', 
              height: 100,
              width: 100,
              excavate: true, // 中間圖片空心
            }}
          />:""
         }

          {/* <div className="text-black bg-main bg-opacity-20 py-6 px-8 rounded-lg mb-10">
      <div className="grid grid-cols-2">
      <p>我的積分</p>
      <p className="text-right">0 </p>
      </div>
      
      <div className="grid grid-cols-3 pt-4 gap-5">
     
        <div className="text-center bg-main rounded-md py-2 cursor-pointer">

          <p className="text-white">轉讓</p>
        </div>
        <div className="text-center bg-main rounded-md py-2 cursor-pointer">

          <p className="text-white">簽到</p>
        </div>

      </div>
    </div> */}

          <div className=" bg-white py-6 px-12 rounded-full  mb-10 mt-6">


            <div className="flex justify-center mb-[14px]">
              <span className="text-main4 font-bold text-xl ">我的時間幣 : {userInfo.timeToken} </span>
            </div>


            <div className="grid grid-cols-2 pt-2 gap-5 ">

              <Link to={"/charitytokentransfer"} className="text-center font-bold gap-x-2 flex items-center justify-center bg-main rounded-full py-3 cursor-pointer">
                <img className="w-6 object-contain" src={transferImg} />
                <p className="text-white">轉讓</p>
              </Link>
              <Link to={"/charitytokendonate"} className="text-center font-bold gap-x-2 flex  items-center justify-center bg-main rounded-full py-3 cursor-pointer">
                <img className="w-6 object-contain" src={donateImg} />
                <p className="text-white">捐贈</p>
              </Link>

            </div>
          </div>

          <div className="grid grid-cols-2 gap-5 hidden">
            <div className="bg-main p-8 rounded-lg text-center cursor-pointer">
              權益兌換
      </div>

            <div className="bg-main p-8 rounded-lg text-center cursor-pointer">
              學習中心
      </div>
          </div>



          <div className="mb-10 grid grid-cols-1 gap-3 justify-between flex-wrap  mx-auto ">
            {
              funcList.map(i => <Link to={i.path} key={i.title} className="bg-white rounded-[10px] flex items-center justify-between p-[22px]">

                <div className="flex items-center">
                  <img className="w-8 mr-[14px]" src={i.img} />
                  <p className="text-center text-main2 font-bold text-lg">{i.title}</p>
                </div>

                <img className="w-10 object-contain " src={arrowrightImg} />
              </Link>
              )

            }

          </div>


          <p className="hidden text-maintext sm:text-xl mt-10 mb-4 ">更多工具</p>
          <div className="hidden grid grid-cols-3 sm:grid-cols-3 gap-5 justify-between flex-wrap max-w-[800px] mx-auto ">
            {
              toolList.map(i => <Link to={i.path} key={i.title}>
                <div className="ratio-1/1 bg-main rounded-full mx-auto mb-1">
                  <div className="ratio-in">

                  </div>

                </div>
                <p className="text-center text-maintext sm:text-xl">{i.title}</p>
              </Link>
              )

            }

          </div>
          <div onClick={() => logout()} className=" cursor-pointer text-white text-xl text-center justify-between bg-main font-bold p-4 rounded-[10px] my-10">
            登出
    </div>

          <div className="hidden text-black flex justify-between bg-main bg-opacity-20 p-4 rounded-lg my-10">
            <div>
              分享時間銀行
    </div>
            <div>
              已分享0人
    </div>
          </div>



        </div >
        :
        <div onClick={() => logout()} className=" cursor-pointer text-white text-xl text-center justify-between bg-main font-bold p-4 rounded-[10px] my-10">
          登出
    </div>
    }
  </>

}

export default UserInfo