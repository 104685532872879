
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState,useRef, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,
  Link

} from "react-router-dom";
import { defaultImg, timestamp2date, gEventArea, getEventType } from '../../global/constants'

import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import Select from 'react-select';
import { assertNodeList } from "react-modal/lib/helpers/ariaAppHider";
import EventView from "../../compontents/EventView";

import eventImg from '../../assets/event.png';

import { useSelector, useDispatch } from 'react-redux';

const JoinEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const eventAreaOption = useSelector(state => [{
    id: 0,
    title: '全部',
    value: '全部',
    label: '全部'
  }, ...state.eventArea]);
  const eventTypeOption = useSelector(state => [{
    id: 0,
    title: '全部',
    value: '全部',
    label: '全部'
  },...state.eventType]);


  const eventArea = useRef(0)
  const eventType = useRef(0)

  const tempArea = useRef([])


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const [dataList, setDataList] = useState(
    [

    ]
  );

  


  function getEvent() {
    let params = {
      accessToken: localStorage.getItem('accessToken')
    }

    let tempArea = []
    if (eventArea.current === 0){
      for(let i = 1 ; i < eventAreaOption.length ; i++ ){
        tempArea.push(eventAreaOption[i].id)
      }
      tempArea = [1,2,3]
    }
    else{ 
      tempArea.push(eventArea.current)
    }

    let tempType = []
    if (eventType.current === 0){
      for(let i = 1 ; i < eventTypeOption.length ; i++ ){
        tempArea.push(eventTypeOption[i].id)
      }
      tempType = [1,2,3]
    }
    else{ 
      tempType.push(eventType.current)
    }
    

    let json = {
      "limit": 100,
      "offset": 0,
      "filter": {
        "area": tempArea,
        "type": tempType
    }
    }
    const api_url = process.env.REACT_APP_API_URL + "/front/activity/search"
    console.log(json)

    axios.post(api_url, json, { params: params })
      .then(response => {

        console.log(response.data)
        setDataList((response.data.data.activities))

      })
      .catch(error => {


      });
  }


  useEffect(() => {

    getEvent()

  }, []);


  return <div className="appWidth py-10" >
 
    <div className="flex items-center gap-x-2 mb-4">
      <img className="w-6 object-contain " src={eventImg} />
      <p className=" text-main2 text-base sm:text-xl font-bold">活動報名</p>
    </div>


    {
      eventAreaOption.length != 0 && eventTypeOption.length != 0 ?
        <div className="mb-5 relative">
          <div className="flex items-center gap-x-5 mb-3">
            <p className="text-main4 text-lg font-bold flex-none">縣市</p>
            <Select
              defaultValue={eventAreaOption[0]}
              options={eventAreaOption}
              className="basic-multi-select text-black w-full"
              classNamePrefix="select"
              onChange={(e) => [eventArea.current = e.id,getEvent()]}
            />
          </div>
          

          <div className="flex items-center gap-x-5 ">
            <p className="text-main4 text-lg font-bold flex-none">分類</p>
            <Select
              defaultValue={eventTypeOption[0]}
              options={eventTypeOption}
              className="basic-multi-select text-black w-full"
              classNamePrefix="select"
              onChange={(e) => [eventType.current = e.id,getEvent()]}
            />
          </div>
        </div> : ""
    }




    {
      dataList.map((i, index) => <EventView key={index} data={i}></EventView>
      )

    }


  </div>

}

export default JoinEvent