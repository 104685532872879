
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Select from 'react-select';

const CharityTokenTransfer = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)


  const sexOption = [
    { value: '不詳', label: '不詳' },
    { value: '男', label: '男' },
    { value: '女', label: '女' },
  ]

  const [sex, setSex] = useState(sexOption[0].value)


  const [sendData, setSendData] = useState([
    {
      title: "系統ID",
      content: "TBX91CJ91J8V",
      placeholder: "系統ID",
      type: "text"
    },
    {
      title: "轉讓數量",
      content: "",
      placeholder: "轉讓數量",
      type: "number"
    },

  ]);
  function sendDataChange(index, value) {

    sendData[index].content = value
    setSendData([...sendData])

  }


  function transferToken() {
    setLoading(true)
    let params = {
      accessToken: localStorage.getItem('accessToken')
    }

    let json = {
      "systemId": sendData[0].content,
      "timeToken": parseInt(sendData[1].content)
    }
    console.log(json)
    const api_url = process.env.REACT_APP_API_URL + "/front/transfer"
    console.log(api_url)

    axios.post(api_url, json, { params: params })
      .then(response => {
        console.log(response.data)
        alert('成功')
        window.location.reload()
        setLoading(false)

      })
      .catch(error => {
        console.log(error)
        alert('失敗')
        setLoading(false)
      });
  }




  useEffect(() => {
    console.log('effect')

    const auth = localStorage.getItem('auth')
    if (auth == "normal") {
      navigate('/joinevent')
    }
    else if (auth == "admin") {
      navigate('/hostevent')
    }
  }, [])




  return <div className="appWidth py-10 max-w-[600px] ">

    <div className="mb-4 w-full flex items-center">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        捐贈
      </div>

    </div>


    {
      !loading ? <>

        <div className="flex mb-10">

          <div className='w-full '>

            {
              sendData.map((i, index) =>
                <div className='w-full' key={i.title}>
                  <div className="mb-0.5 w-full flex items-center">

                    <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                      {i.title}
                    </div>

                  </div>

                  <div className=" overflow-hidden rounded-md  relative md:flex bg-white border-solid border border-[#CCCCCC]  w-full mb-6">

                    <div className="w-full  text-base opacity-80 " >
                      <input id="inputID" className='px-2 py-[5px] w-full   placeholder-gray-400 text-black' value={i.content} type={i.type} placeholder={i.placeholder} onChange={(e) => sendDataChange(index, e.target.value)} />
                    </div>

                  </div>
                </div>
              )}


          </div>


        </div>


        <div className="grid grid-cols-2 justify-between gap-3 py-4">
          <button className="w-full gradiBtn p-4 specFont  shadow-md" onClick={() => transferToken()} >捐贈</button>
          <Link to="/home" className="w-full text-center bg-gray-400 rounded-xl p-4 specFont shadow-md" >取消</Link>
        </div>
      </>
        : <p className="text-center text-black">載入中...</p>
    }




  </div>


}

export default CharityTokenTransfer