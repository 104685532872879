import api from './api.jsx'

export default class UserApi {

    static login(data) {
        return api({
            url: '/front/login',
            method: "POST",
            data
        })
    }
    static getSystem() {
        return api({
            url: '/front/system',
            method: "GET"
        })
    }
    static productSearch(data) {
        return api({
            url: '/front/product/search?accessToken=' + localStorage.getItem('accessToken'),
            method: "POST",
            data
        })
    }
    static getProductById(data) {
        return api({
            url: `/front/product?accessToken=${localStorage.getItem('accessToken')}&}&productId=${data.id}`,
            method: "GET"
        })
    }
    static purchaseSearch(data) {
        return api({
            url: '/front/user/purchase/search?accessToken=' + localStorage.getItem('accessToken'),
            method: "POST",
            data
        })
    }
    static purchase(data) {
        return api({
            url: '/front/user/purchase?accessToken=' + localStorage.getItem('accessToken'),
            method: "POST",
            data
        })
    }
    static purchaseUse(data) {
        return api({
            url: '/front/user/purchase/use?accessToken=' + localStorage.getItem('accessToken'),
            method: "POST",
            data
        })
    }



}