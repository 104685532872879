
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';

import { Link } from "react-router-dom";

import { QrReader } from 'react-qr-reader';

import {
  BrowserRouter as Router,
  useNavigate,
  useSearchParams

} from "react-router-dom";

import defaultIconImg from '../../assets/bg.jpg';


import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Swiper from '../../compontents/Swiper'
import { useSelector, useDispatch } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { timestamp2date } from "../../global/constants";


const QRcodeReader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  const token = useRef("")
  const type = useRef("")


  const [eventData, setEventData] = useState({});
  const eventDataRef = useRef(undefined);




  const [successList, setSuccessList] = useState([]);


  function getStartEventByToken(token) {
    let json = {
      "startComfrimLink": token
    }
    const api_url = process.env.REACT_APP_API_URL + "/front/verify/startComfrimLink"
    axios.post(api_url, json)
      .then(response => {
        console.log(response.data)
        let temp = response.data.data.activity
        setEventData(temp)
        eventDataRef.current = temp
      })
      .catch(error => {
        setEventData({ title: "發生異常" })

      });
  }

  function getEndEventByToken(token) {
    let json = {
      "endComfrimLink": token
    }
    const api_url = process.env.REACT_APP_API_URL + "/front/verify/endComfrimLink"
    axios.post(api_url, json)
      .then(response => {
        console.log(response.data)
        let temp = response.data.data.activity
        setEventData(temp)
        eventDataRef.current = temp
      })
      .catch(error => {
        setEventData({ title: "發生異常" })
      });
  }

  function commitEventByToken(token, systemId, step) {
    setLoading(true)
    let json = {
      "systemId": systemId,
      "startComfrimLink": token
    }
    console.log(step)
    if (step == 'first') {
      json = {
        "systemId": systemId,
        "startComfrimLink": token
      }
    }
    else {
      json = {
        "systemId": systemId,
        "endComfrimLink": token
      }
    }


    const api_url = process.env.REACT_APP_API_URL + "/front/verify/" + step
    console.log(api_url)
    console.log(json)
    axios.post(api_url, json)
      .then(response => {
        toast.success("簽到成功 " + systemId)
        setSuccessList((prev) => [systemId, ...prev])
        setLoading(false)
      })
      .catch(error => {
        console.log('ssss')
        console.log(error)
        if (error.response.data.message == "unfound user") {
          toast.error("查無該用戶或二維碼錯誤 " + systemId)
        }
        else if (error.response.data.message == "unfound user join activity record") {
          if (step == 'first') {
            toast.error("查無參加該活動 " + systemId)
          }
          else {
            toast.error("已簽退成功或查無參加該活動 " + systemId)
          }
        }
        else if (error.response.data.message == "unfound activity or not the allow date to join") {
          toast.error("已經超出截止時間 " + systemId)
        }
        else if (error.response.data.message == "unfound user join activity record or already joined") {
          if (step == 'first') {
            toast.warning("已簽到成功 " + systemId)
          }
          else {
            toast.warning("已簽退成功 " + systemId)
          }
        }
        else {
          toast.error("發生異常 " + systemId)
        }
        //alert(JSON.stringify(error))
        setLoading(false)
      });
  }



  const currentTime = useRef(0)

  function commitEvent(token, systemId) {

    if ((Date.now() - currentTime.current) < 2000) {
      return
    }
    currentTime.current = Date.now()

    if (eventData == undefined) {
      toast.error('發生異常')
      return
    }

    if (loading == true) {
      return
    }

    setLoading(true)
    //toast.success('掃' + Date.now() + currentTime.current)

    console.log(type.current)
    if (type.current == "start") {
      if ((Date.now() / 1000) < eventDataRef.current.startDate) {
        toast.error('還沒到開始簽到時間')
        return
      }
      commitEventByToken(token, systemId, 'first')
    }
    else if (type.current == "end") {
      if ((Date.now() / 1000) > eventDataRef.current.endDate) {
        toast.error('已經超出簽退截止時間')
        return
      }
      commitEventByToken(token, systemId, 'second')
    }
  }



  useEffect(() => {

    token.current = searchParams.get('token')
    type.current = searchParams.get('type')


    if (type.current == "start") {
      getStartEventByToken(token.current)
    }
    else if (type.current == "end") {
      getEndEventByToken(token.current)
    }



  }, []);


  return <div className="appWidth py-8">
    <ToastContainer position="top-center" />

    {
      eventData != undefined ?
        eventData.title == "發生異常" ?
          <div className="px-4 py-2 max-w-[400px] w-full mx-auto text-center bg-main text-white border-main border-solid border-2 rounded-[4px]" >
            <p className="font-bold">連結異常</p>
            <p className="font-bold">請確定連結正確</p>
          </div> :
          <>
            <div className="px-4 py-2 max-w-[400px] w-full mx-auto text-center bg-main text-white border-main border-solid border-2 rounded-t-[4px]" >
              <p className="font-bold">{eventData.title}</p>
              <p>{timestamp2date(type.current == "start" ? eventData.startDate : eventData.endDate)} {type.current == "start" ? "開始簽到" : "截止簽退"}</p>
            </div>

            <div className="w-full  mx-auto max-w-[400px] border-solid  border-8 border-black bg-black">
              <QrReader
                constraints={{ facingMode: 'environment' }}
                onResult={(result, error) => {
                  if (!!result) {
                    setData(result?.text);
                    commitEvent(token.current, result?.text)
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
                className="w-full h-full "
              />

            </div>
            <div className="flex my-4">

            </div>
            <p className="text-black text-center mb-4 hidden">{data}</p>

            <div className="w-full max-w-[400px] mx-auto text-black mt-6">
              <p className="font-bold">已簽到成功:</p>
              {
                successList.map((i, index) => <div key={i}>
                  <p>{i}</p>
                  <div className="w-full h-0.5 bg-main"></div>

                </div>)
              }

            </div>

          </>


        :
        <div></div>

    }



  </div>

}

export default QRcodeReader