
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,
  useSearchParams,
  Link
} from "react-router-dom";
import { defaultImg, timestamp2date, gEventArea, gEventType } from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import { data } from "autoprefixer";

import DetailSwiper from '../../compontents/DetailSwiper'


const ActiveDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [dataImage, setDataImage] = useState([]);

  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const [dataList, setDataList] = useState(
    [
    ]
  );



  const [data, setData] = useState(undefined);

  function getByEventId(selId) {
    let params = {
      "activityId": selId,
      accessToken: localStorage.getItem('accessToken')
    }

    const api_url = process.env.REACT_APP_API_URL + "/front/activity"


    axios.get(api_url, { params: params })
      .then(response => {

        console.log(response.data)
        let temp = response.data.data.activity
        // let tempData = [
        //   {
        //     title: '標題',
        //     value: temp.title
        //   },
        //   {
        //     title: '報名截止時間',
        //     value: timestamp2date(temp.join_deadline)
        //   },
        //   {
        //     title: '活動開始日期時間',
        //     value: timestamp2date(temp.start_date)
        //   },
        //   {
        //     title: '活動結束日期時間',
        //     value: timestamp2date(temp.end_date)
        //   },
        //   {
        //     title: '參加者須知',
        //     value: temp.introduction.replaceAll('\n', '<br/>')
        //   },
        //   {
        //     title: '活動內容',
        //     value: temp.message.replaceAll('\n', '<br/>')
        //   },
        //   {
        //     title: '活動人數上限',
        //     value: temp.maximum_people
        //   },
        //   {
        //     title: '詳細地址',
        //     value: temp.address
        //   },
        //   {
        //     title: '活動地區',
        //     value: gEventArea.find(i => i.id === parseInt(temp.area_id)).title
        //   },
        //   {
        //     title: '活動類型',
        //     value: gEventType.find(i => i.id === parseInt(temp.area_id)).title
        //   },

        // ]

        let tempImages = []

         for (let i = 0 ; i < temp.images.length ; i = i + 1){
          tempImages.push(temp.images[i].url)
          console.log(temp.images[i].url)
         }

         setDataImage(tempImages)
        
        //setDataImage(temp&&temp.images.length > 0?temp.images[0].url:"")
        //setDataList(tempData)

        setData(temp)


      })
      .catch(error => {
        console.log(error)

      });
  }

  function joinEvent() {
    let params = {
      "activityId": data.id,
      accessToken: localStorage.getItem('accessToken')
    }
    let json = {

    }
    const api_url = process.env.REACT_APP_API_URL + "/front/userActivity"
    console.log(json)

    axios.post(api_url, json, { params: params })
      .then(response => {

        console.log(response.data)
        alert('參加成功')

      })
      .catch(error => {
        console.log(error)
        if (error.response.data.message === "you already join this activity"){
          alert('已參加成功，至活動紀錄查看')
        }
        else{
          alert('參加失敗')
        }

      });
  }

  const [isFisrt, doneFisrt] = useState(false)


  useEffect(() => {
    console.log('effect')

    getByEventId(searchParams.get('id'))


    if (isFisrt == false) {
      doneFisrt(true)
      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }
  }, []);


  return <div className="appWidth py-8" >

    {data != undefined ?
      <>
        <div className=" border-main border-solid border rounded-[10px]  bg-white">

          <div className="p-5">

            <DetailSwiper images={dataImage}></DetailSwiper>

            {/* <img className="w-full mb-5 rounded-[10px]" src={dataImage[0].url} />  */}

            <div className="mt-5 mb-5 text-main3 text-xl font-semibold">
              {data.title}
            </div>

            <div className="mb-[6px] text-main3 text-sm">
              活動地區： {data.area.title}
            </div>
            <div className="mb-[6px] text-main3 text-sm">
              詳細地址： {data.address}
            </div>
            <div className="mb-5 text-main3 text-sm">
              活動類型： {data.type.title}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
              <div className="bg-main bg-opacity-80 rounded-lg p-3 text-sm text-center">
                <p className="text-base font-semibold">報名截止</p>
                <p >{timestamp2date(data.joinDeadline)}</p>
              </div>
              <div className="bg-main bg-opacity-90 rounded-lg p-3 text-sm text-center">
                <p className="text-base font-semibold">活動開始</p>
                <p >{timestamp2date(data.startDate)}</p>
              </div>
              <div className="bg-main bg-opacity-100 rounded-lg p-3 text-sm text-center">
                <p className="text-base font-semibold">活動結束</p>
                <p >{timestamp2date(data.endDate)}</p>
              </div>

            </div>
          </div>

          <div className="bg-main w-full h-px"></div>
          <div className="p-5">
            <p className="text-base text-main4 font-semibold">參加者須知</p>
            <div className="text-main3 flex justify-between text-lg" dangerouslySetInnerHTML={{ "__html": data.message }}></div>
          </div>

          <div className="bg-main w-full h-px"></div>
          <div className="p-5">
            <p className="text-base text-main4 font-semibold">活動內容</p>
            <div className="text-main3 flex justify-between text-lg" dangerouslySetInnerHTML={{ "__html": data.introduction }}></div>
          </div>
          <div className="bg-main w-full h-px"></div>
          <div className="p-5 grid grid-cols-2 gap-x-[15px] text-sm md:text-base">
            <div className="border-main border-solid border rounded-[10px] flex justify-between px-4 py-3">
              <div className="text-main5 font-semibold">活動人數上限</div>
              <div className="text-main3">{data.maximumPeopleToJoin}</div>
            </div>
            <div className="border-main border-solid border rounded-[10px] flex justify-between px-4 py-3">
              <div className="text-main5 font-semibold">已報名人數</div>
              <div className="text-main3">{data.currentPeopleToJoin}</div>
            </div>

          </div>
        </div>
        <div className="grid grid-cols-1 justify-between gap-4 mt-6">
          <button className=" w-full bg-main5 rounded-full p-3  boxShadow text-xl font-semibold" onClick={() => joinEvent()} >參加</button>
          <Link to="/joinevent" className="w-full">
            <div className="w-full  border-main border-solid border text-xl font-semibold rounded-full p-3 text-main boxShadow text-center">取消</div>
          </Link>
        </div>
      </>
      : ""}




  </div>

}

export default ActiveDetail