
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,
  useSearchParams,
  Link
} from "react-router-dom";

import { defaultImg } from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import { timestamp2date, gActiveType } from "../../global/constants";
import moment from "moment"

import DetailSwiper from '../../compontents/DetailSwiper'

const ActiveDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  function getByArticleId(selId) {
    let params = {
      accessToken: localStorage.getItem('accessToken'),
      "blogId": selId,
    }

    const api_url = process.env.REACT_APP_API_URL + "/front/blog"
    console.log(params)

    axios.get(api_url, { params: params })
      .then(response => {
        console.log(response.data.data.blog)

        let temp = response.data.data.blog
        let tempData = [
          {
            title: '標題',
            value: temp.title
          },
          {
            title: '內容',
            value: temp.content
          },
          {
            title: '發文日期',
            value: moment(temp.createdAt).format('YYYY/MM/DD HH:mm')
          },
          {
            title: '動態類型',
            value: temp.type.title
          }
        ]



        let tempImages = []

        for (let i = 0; i < temp.images.length; i = i + 1) {
          tempImages.push(temp.images[i].url)
          console.log(temp.images[i].url)
        }

        setDataImage(tempImages)

        setDataList(tempData)

      })
      .catch(error => {
        console.log(error)

      });
  }

  const [dataImage, setDataImage] = useState([]);

  const [data, setData] = useState(undefined);

  const [dataList, setDataList] = useState(
    [
      {
        title: '標題',
        value: ""
      },
      {
        title: '內文',
        value: ""
      },
      {
        title: '發文日期',
        value: ""
      },
      {
        title: '動態類型',
        value: ""
      },

    ]
  );

  useEffect(() => {
    console.log('effect')

    getByArticleId(searchParams.get('id'))

  }, []);


  return <div className="appWidth py-8" >



    {true ?
      <>
        <div className=" border-main border-solid border rounded-[10px]  bg-white">

          <div className="p-5">



            {
              dataImage.length > 0 ?
                <DetailSwiper images={dataImage}></DetailSwiper>
                : ""
            }

            <div className="mt-5 text-xl mb-5 text-main3 font-semibold">
              {dataList[0].value}
            </div>

            <div className="mb-[6px] flex justify-between text-main3 text-sm">
              <div>{dataList[3].value}</div>
              <div>{dataList[2].value}</div>
            </div>

            <div className="text-main3 flex justify-between text-lg" dangerouslySetInnerHTML={{ "__html": dataList[1].value }}></div>

          </div>


        </div>
        <div className="grid grid-cols-1 justify-between gap-4 mt-6">

          <Link to="/article" className="w-full">
            <div className="w-full  border-main border-solid border text-xl font-semibold rounded-full p-3 text-main boxShadow text-center">返回</div>
          </Link>
        </div>
      </>
      : ""
    }




  </div>

}

export default ActiveDetail