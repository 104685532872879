import { brandLink } from "../global/constants"
import { useTranslation } from "react-i18next";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';


const Loading = () => {
  const location = useLocation()

  const loading = useSelector(state => state.loading);

  return <>
    {
      loading == true ?
        <div className="fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-70 z-[5000]">
          <div className="flex w-full h-full justify-center items-center">
            <div className=" pointer-events-none text-xl lg:text-2xl text-white specFont !font-bold text-center textShadow">
              載入中...
        </div>

          </div>

        </div> : ""
    }
  </>
}
export default Loading