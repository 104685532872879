import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"

import "./style.css";

import axios from "axios";

// import required modules
import { Autoplay, Pagination } from "swiper";


export default function App(props) {

  const [banner, setBanner] = useState(props.images)

  function getBanner() {

    let json = {
    }
    const api_url = process.env.REACT_APP_API_URL + "/banner/get"
    console.log(api_url)

    axios.post(api_url, json)
      .then(response => {

        setBanner(response.data.data)


      })
      .catch(error => {


      });
  }

  useEffect(() => {

    //getBanner()

  }, []);

  return (
    <>
      <Swiper
        slidesPerView={1.2}
        centeredSlides={true}
        spaceBetween={10}
        grabCursor={true}
        loop={true}
        pagination={{
        clickable: true,
        }}
        autoplay={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper autoplay"
      >
        {
          banner.map((i, index) => <SwiperSlide key={index} className="rounded-md ratio-16/9 overflow-hidden"><div className="ratio-in"><img src={i} className=" object-cover"  alt={i}/></div></SwiperSlide>)
        }



      </Swiper>
    </>
  );
}
