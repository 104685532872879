
const initState = {
  menuItemData: [
    "1",
    "2",
  ],
  userPoint: 0,
  userInfo: undefined,
  loading: false,
  system: undefined,

  
  articleType :[
  ], 
  eventArea :[],
  eventType :[]
};

const itemReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      const menuItemCopy = state.menuItemData.slice();
      return { menuItemData: [action.payload.itemNew].concat(menuItemCopy) };
    }
    case 'CLEAN_ITEM': {
      return { menuItemData: [] };
    }
    case 'UPDATE_SYSTEM': {
      return { ...state,articleType: action.payload.articleType, eventType: action.payload.eventType ,eventArea: action.payload.eventArea,system: action.payload.system};
    }
    case 'UPDATE_USERINFO': {
      return { ...state,userInfo: action.payload.userInfo };
    }
    case 'UPDATE_USERPOINT': {
      return { ...state, userPoint: action.payload.userPoint };
    }
    case 'UPDATE_LOADING': {
      return { ...state, loading: action.payload.loading };
    }
    default:
      return state;
  }
};


export { itemReducer };