
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState,useRef, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,
  Link

} from "react-router-dom";
import { defaultImg, timestamp2date } from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Select from 'react-select';
import moment from 'moment'

import eventImg from '../../assets/event.png'

import { useSelector, useDispatch } from 'react-redux';


const CharityStory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const articleTypeOption = useSelector(state => [{
    id: 0,
    title: '全部',
    value: '全部',
    label: '全部'
  },...state.articleType]);

  const articleType = useRef(0)

  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');






  const [dataList, setDataList] = useState(
    []
  );


  function getArticle() {
    let params = {
      accessToken: localStorage.getItem('accessToken')
    }

    let tempType = []
    if (articleType.current === 0){
      for(let i = 1 ; i < articleTypeOption.length ; i++ ){
        tempType.push(articleTypeOption[i].id)
      }
      tempType = [1,2] // all
    }
    else{ 
      tempType.push(articleType.current)
    }

    console.log(tempType)

    let json = {
      "limit": 100,
      "offset": 0,
      "filter": {
        "type": tempType
    }
    }
    const api_url = process.env.REACT_APP_API_URL + "/front/blog/search"
    console.log(json)

    axios.post(api_url, json, { params: params })
      .then(response => {

        console.log(response.data)
        setDataList((response.data.data.blogs))

      })
      .catch(error => {


      });
  }



  useEffect(() => {

    getArticle()

  }, []);


  return <div className="appWidth py-10" >

    <div className="flex items-center gap-x-2 mb-4">
      <img className="w-6 object-contain " src={eventImg} />
      <p className=" text-main2 text-base sm:text-xl font-bold">動態</p>
    </div>

    <div className="flex items-center gap-x-5 mb-3">
      <p className="text-main4 text-lg font-bold flex-none">分類</p>

      <Select
        defaultValue={articleTypeOption[0]}
        options={articleTypeOption}
        className="basic-multi-select   text-black w-full"
        classNamePrefix="select"
        onChange={(e)=>[articleType.current = e.id,getArticle()]}

      />
    </div>

    {
      dataList.length === 0 ?
        <div className="text-center text-black ">暫無動態</div>
        : ""
    }







    {
      dataList.map(i => <Link to={`/articledetail?id=${i.id}`} key={i.id} className="grid grid-cols-3 rounded-md border border-solid border-gray-400 overflow-hidden mb-2">

        <div className=" col-span-2 px-5 py-3 ">
          <div className="flex flex-col  h-full">
            <div className="h-full">
              <p className="text-black text-lg truncate">{i.title}</p>
              <p className="text-gray-500  line-clamp-2  relative" dangerouslySetInnerHTML={{__html:i.content.replaceAll('\n', '<br/>')}}></p>
            </div>
            <div className="text-gray-500 flex justify-between text-sm">
              <div className="flex items-center  ">
                <img className="w-4 h-4 mr-1 hidden" src={require('../../assets/view.png')} />
                <p className="">{i.view}</p>
              </div>
              {moment(i.createdAt).format('YYYY/MM/DD HH:mm')}
            </div>
          </div>

        </div>

        <div className="ratio-1/1 bg-main bg-opacity-10">
          <img className="ratio-in my-auto" src={i.images.length>0?i.images[0].url:defaultImg} />
        </div>

      </Link>)

    }


  </div>

}

export default CharityStory