
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,
  Link

} from "react-router-dom";
import { defaultImg, timestamp2date2, gEventArea, getEventType } from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import Select from 'react-select';
import { assertNodeList } from "react-modal/lib/helpers/ariaAppHider";
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';


const JoinEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userInfo = useSelector(state => state.userInfo);

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const [dataList, setDataList] = useState(
    []
  );
  const [totalToken, setTotalToken] = useState(0);

  const dataValue = useRef([])


  function getTokenRecord() {
    let params = {
      accessToken: localStorage.getItem('accessToken')
    }
    let json = {
      "limit": 100,
      "offset": 0
    }
    const api_url = process.env.REACT_APP_API_URL + "/front/transfer/search"
    console.log(api_url)

    axios.post(api_url, json, { params: params })
      .then(response => {

        console.log(response.data)
        let temp = 0
        for (let i = 0; i < response.data.data.timeTokenRecords.length; i = i + 1) {
          console.log(response.data.data.timeTokenRecords[i].toUser.systemId)
          if (response.data.data.timeTokenRecords[i].toUser.systemId == userInfo.systemId) {
            temp = temp + response.data.data.timeTokenRecords[i].timeToken
          }
          else {
            temp = temp - response.data.data.timeTokenRecords[i].timeToken
          }
        }
        setTotalToken(temp)

        setDataList(dataList.concat(response.data.data.timeTokenRecords))

      })
      .catch(error => {
        console.log(error)
      });
  }


  function getByEventId(selId, index) {
    let json = {
      "activityId": parseInt(selId),
    }
    const api_url = process.env.REACT_APP_API_URL + "/activity/getByActivityId"
    console.log(json)

    axios.post(api_url, json)
      .then(response => {

        console.log(response.data)
        dataValue.current[index] = { ...dataValue.current[index], title: response.data.data[0].title }
        console.log(dataValue.current)

        setDataList(function (prev) {
          return prev.map((i, selIndex) => index == selIndex ? dataValue.current[index] : i)
        })


      })
      .catch(error => {
        console.log(error)

        setDataList(function (prev) {
          return prev.map((i, selIndex) => index == selIndex ? dataValue.current[index] : i)
        })

      });
  }



  const [isFisrt, doneFisrt] = useState(false)

  async function getInitData() {

  }




  useEffect(() => {
    console.log('effect')


    if (userInfo != undefined){
      getTokenRecord()

    }
    


  }, [userInfo]);


  return <div className="appWidth py-4" >

    {/* <Select
    defaultValue={eventRegionOption[0]}
    options={eventRegionOption}
    className="basic-multi-select mb-2 text-black"
    classNamePrefix="select"
    onChange={(e)=>setEventRegion(e.value)}
  />

  <Select
    defaultValue={eventTypeOption[0]}
    options={eventTypeOption}
    className="basic-multi-select mb-4 text-black"
    classNamePrefix="select"
    onChange={(e)=>setEventType(e.value)}
  /> */}

    <div className="mb-0.5 w-full flex items-center">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        時間存摺
                    </div>

    </div>
    <div className="w-full min-h-screen overflow-x-scroll">

      {
        userInfo ? <>
          <table className="w-full">
            <thead >
              <tr className="text-gray-600 text-base text-left border-b border-solid border-gray-400">
                <th className="w-20">日期</th>
                <th className="w-20">事件</th>
                <th className="w-12">工作</th>

                <th className="w-16">數量</th>
              </tr>
            </thead>
            <tbody>
              {
                dataList.map(i => <tr key={i.id} >

                  <td className="text-black text-xs ">{moment(i.createdAt).format('YYYY/MM/DD')}</td>
                  <td className="text-black text-xs ">{i.description}</td>
                  <td className="text-black text-xs ">{i.toUser.systemId == userInfo.systemId ? `轉入` : '轉出'}</td>


                  <td className="text-black text-xs"> {i.timeToken} <span className="text-gray-500 text-sm"></span></td>

                </tr>)

              }
              <tr className="border-t border-gray-400 border-solid">
              <td className="text-black text-xs">總結</td>
              <td></td>
              <td></td>
              <td className="text-black text-xs"> {totalToken} <span className="text-gray-500 text-sm"></span></td>

              </tr>
            </tbody>
          </table>
      
        </>
          : ""
      }

    </div>


  </div>

}

export default JoinEvent