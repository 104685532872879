
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll ,scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
const JoinTraining = () =>{
    const { t } = useTranslation();
    const navigate = useNavigate();
    

    const [userInfo, setUserInfo] = useState({});

    const [eventInfo, setEventInfo] = useState({});
  
    const [joinCode, setJoinCode] = useState('');
    const [endCode, setEndCode] = useState('');

    const [point, setPoint] = useState('');


      const [isFisrt,doneFisrt] = useState(false)
    

    useEffect(() => {
      console.log('effect')

      const auth = localStorage.getItem('auth')

      
       if (isFisrt == false){
         doneFisrt(true)
  

      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

       }

  },[]);

  
    return <div className="appWidth " >
        
    
    </div>

}

export default JoinTraining