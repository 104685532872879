
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import {defaultImg,ErrorImg} from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
const JoinTraining = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const [dataList, setDataList] = useState(
    [
      {
        title: '志願新力量',
        maxppl: 100,
        endDate: Date.now() + 100000
      },
      {
        title: '志願新力量',
        maxppl: 100,
        endDate: Date.now() + 100000
      }
    ]
  );


  const [isFisrt, doneFisrt] = useState(false)


  useEffect(() => {
    console.log('effect')

    const auth = localStorage.getItem('auth')


    if (isFisrt == false) {
      doneFisrt(true)


      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }

  }, []);


  return <div className="appWidth py-2" >

    {
      dataList.map(i => <div className="grid grid-cols-3 rounded-md border border-solid border-gray-400 overflow-hidden mb-2">
        <div className="ratio-1/1">
        <img className="ratio-in" src={'asdas'} onError={ErrorImg} />
        </div>
       
        <div className=" col-span-2 p-8">
          <p className="text-black">{i.title}</p>
          <p className="text-black">活動人數上限: {i.maxppl}</p>
          <p className="text-black">截止時間: </p>
        </div>

      </div>)

    }


  </div>

}

export default JoinTraining