
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';

import JoinEvent from '../../assets/home/team.png';
import tokenShop from '../../assets/home/store.png';
import tokenTransfer from '../../assets/home/transfer.png';
import tokenDonate from '../../assets/home/donation.png';

import eventImg from '../../assets/event.png';
import arrowrightImg from '../../assets/arrowright.png';



import { defaultImg } from "../../global/constants";


import EventView from "../../compontents/EventView.js";


import { Link } from "react-router-dom";

import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Swiper from '../../compontents/Swiper'

import { useSelector, useDispatch } from 'react-redux';

import banner1Img from '../../assets/1.jpg'
import banner2Img from '../../assets/2.jpg'
import banner3Img from '../../assets/3.jpg'
import banner4Img from '../../assets/4.jpg'

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const system = useSelector(state => state.system);



  const [totalUser, setTotalUser] = useState("");
  const [totalGainToken, setTotalGainToken] = useState("");


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const funcList = [
    {
      title: '活動報名',
      path: '/joinevent',
      img: JoinEvent
    },
    // {
    //   title:'培訓報名',
    //   path:'/jointraining'
    // },
    // {
    //   title:'加入團隊',
    //   path:'/jointeam'
    // },
    // {
    //   title:'公益故事',
    //   path:'/charitystory'
    // },
    // {
    //   title:'積分公益',
    //   path:'/charityscore'
    // },
    // {
    //   title:'積分兌換',
    //   path:'/charityscoregift'
    // },
    {
      title: '服務兌換',
      path: '/charitytokengift',
      img: tokenShop
    },
    {
      title: '轉讓',
      path: '/charitytokentransfer',
      img: tokenTransfer
    },
    {
      title: '捐贈',
      path: '/charitytokendonate',
      img: tokenDonate
    },
    // {
    //   title:'服務地圖',
    //   path:'/servicemap'
    // },
    // {
    //   title:'許願池',
    //   path:'/joblist'
    // },
    // {
    //   title:'公益成就',
    //   path:'/charityrecord'
    // },


  ]

  const [dataList, setDataList] = useState([

  ])



  const [isFisrt, doneFisrt] = useState(false)

  function getEvent() {
    let params = {
      accessToken: localStorage.getItem('accessToken')
    }

    let json = {
      "limit": 10,
      "offset": 0,
      "filter": {
        "area": [1, 2, 3],
        "type": [1, 2, 3],
      }
    }
    const api_url = process.env.REACT_APP_API_URL + "/front/activity/search"
    console.log(api_url)

    axios.post(api_url, json, { params: params })
      .then(response => {

        console.log(response.data)
        setDataList(response.data.data.activities)

      })
      .catch(error => {


      });
  }

  function getTotalVolunteer() {

    let json = {}
    const api_url = process.env.REACT_APP_API_URL + "/system/getTotalVolunteer"
    console.log(api_url)

    axios.post(api_url, json)
      .then(response => {
        console.log(response.data.data)
        setTotalUser(response.data.data.total)
      })
      .catch(error => {
        console.log(error)

      });
  }

  function getTotalVolunteerEarn() {

    let json = {}
    const api_url = process.env.REACT_APP_API_URL + "/system/getTotalVolunteerEarn"
    console.log(api_url)

    axios.post(api_url, json)
      .then(response => {
        console.log(response.data.data)
        setTotalGainToken(response.data.data.sum)
      })
      .catch(error => {
        console.log(error)

      });
  }





  useEffect(() => {
    console.log('effect')

    getEvent()




  }, []);


  return <div className="max-w-[800px] mx-auto pt-8" >


    <Swiper images={[banner1Img,banner2Img,banner3Img,banner4Img]}></Swiper>

   <div className="appWidth">
    {
      system != undefined ?
        <div className="grid grid-cols-2 gap-2 md:gap-4 mt-8">
          <div className="border-solid border-main border-2 rounded-[10px] py-[10px] px-3 ">
            <div className=" text-maintext text-base text-md font-semibold text-center ">志工人數 : <span className=""> {system.totalUser}</span></div>
          </div>
          <div className="border-solid border-main border-2 rounded-[10px] py-[10px] px-3 ">
            <div className=" text-maintext text-base text-md font-semibold text-center ">時間幣 : <span className=""> {system.totalTimeToken}</span></div>
          </div>

        </div>
        : ""
    }

    <div className="my-10 grid grid-cols-4 gap-2 md:gap-4 justify-between flex-wrap max-w-[800px] mx-auto ">
      {
        funcList.map(i => <Link to={i.path} key={i.title}>
          <div className="w-full px-[4px] pt-[18px] pb-[10px] bg-main rounded-[10px] mx-auto mb-1">

            <div className="w-full h-full flex mb-[12px]">
              <img className="w-3/5 m-auto" src={i.img} />
            </div>

            <p className="text-center text-white text-sm md:text-xl font-semibold  ">{i.title}</p>

          </div>

        </Link>
        )

      }

    </div>

    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center gap-x-2">
        <img className="w-6 object-contain " src={eventImg} />
        <p className=" text-main2 text-base sm:text-xl font-bold">最新活動</p>
      </div>

      <Link to="/joinevent">
        <img className="w-10 object-contain " src={arrowrightImg} />
      </Link>
    </div>

    <div className="mb-10 md:mb-20 grid grid-cols-1 gap-3 justify-between flex-wrap max-w-[800px] mx-auto ">
      {
        dataList.map((i, index) => <EventView key={index} data={i}></EventView>
        )

      }

    </div>


  </div>
  </div>

}

export default Home