import {
  BrowserRouter as Router,
  useNavigate,
  useSearchParams

} from "react-router-dom";

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import logoImg from '../../assets/logo.jpg'
import UserApi from "../../services/UserApi";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showRegisterDiv, setShowRegisterDiv] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [selUnitId, setSelUnitId] = useState(null)

  const lineToken = useRef('')


  const clientId = "1657314920"
  let weburl = ""
  const line_redirect_uri = useRef("")

  const [lineURI, setLineURI] = useState("")
  const [loading, setLoading] = useState(false)
  const [sendData, setSendData] = useState([
    {
      title: "帳號",
      content: "",
      placeholder: "帳號"
    },
    {
      title: "密碼",
      content: "",
      placeholder: "密碼"
    },
  ]);
  function sendDataChange(index, value) {

    sendData[index].content = value
    setSendData([...sendData])

  }


  function volunteerLogin() {

    console.log('sss')
    let json = {
      "account": sendData[0].content,
      "password": sendData[1].content
    }
    const api_url = process.env.REACT_APP_API_URL + "/volunteer/login"


    axios.post(api_url, json)
      .then(response => {
        if (response.data.message == 'done') {
          localStorage.setItem('userID', JSON.stringify(response.data.data))
          localStorage.setItem('auth', "normal")
          navigate('/joinevent')
        }
        else {
          alert("登入失敗")
        }

      })
      .catch(error => {

        alert("登入失敗")
      });
  }

  function adminLogin() {

    let json = {
      "account": sendData[0].content,
      "password": sendData[1].content
    }
    const api_url = process.env.REACT_APP_API_URL + "/admin/login"
    console.log(api_url)

    axios.post(api_url, json)
      .then(response => {
        if (response.data.message == 'done') {
          localStorage.setItem('userID', JSON.stringify(response.data.data))
          localStorage.setItem('auth', "admin")
          navigate('/hostevent')
        }
        else {
          alert("登入失敗")
        }
      })
      .catch(error => {

        alert("登入失敗")
      });
  }

  function getLineIDToken() {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("client_id", clientId);
    params.append("client_secret", "b49fd424b4cc5290ebf01b27c0e42323");
    params.append("code", searchParams.get('code'));
    params.append("redirect_uri", weburl);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let api_url = "https://api.line.me/oauth2/v2.1/token";

    axios
      .post(api_url, params, config)
      .then((result) => {
        console.log("getLineCode");
        console.log(result.data.id_token);

        getLineID(result.data.id_token);


        // Do somthing
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // Do somthing
      });
  }
  async function getLineID(id_token) {
    console.log(id_token);

    const params = new URLSearchParams();
    params.append("id_token", id_token);
    params.append("client_id", clientId);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let api_url = "https://api.line.me/oauth2/v2.1/verify";

    axios
      .post(api_url, params, config)
      .then((result) => {
        console.log(result);

        usersignin("line", result.data.sub);
        lineToken.current = result.data.sub

        // Do somthing
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // Do somthing
      });
  }

  async function usersignin(loginType, idToken) {

    console.log(idToken);
    let json = {
      lineLoginToken: idToken
    };

    let api_url = process.env.REACT_APP_API_URL + "/front/login";
    try {
      const response = await axios.post(api_url, json);
      console.log(json);
      console.log(response.data.message);

      localStorage.setItem('userID', idToken)
      localStorage.setItem('accessToken', response.data.data.accessToken)
      window.location.reload()

    } catch (error) {
      if (error.response.status == 403) {

        setShowRegisterDiv(true)
        // userLineCreate(idToken)
      }
      else {
        setLoading(false);
      }
      console.error(error);
    }
  }

  async function userLineCreate() {
    let idToken = lineToken.current

    setLoading(true);

    console.log(idToken);
    let json = {
      "lineLoginToken": idToken,
      "unitId": selUnitId

    };

    let api_url = process.env.REACT_APP_API_URL + "/front/register";
    try {
      const response = await axios.post(api_url, json);
      console.log(json);
      console.log(response.data.message);


      localStorage.setItem('userID', idToken)
      localStorage.setItem('accessToken', response.data.data.user.accessToken)
      window.location.reload()

    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  }



  async function getSystem() {
    try {
      let res = await UserApi.getSystem()
      console.log(res.data.data.units)
      setUnitList(res.data.data.units)

    }
    catch (errors) {
      console.log(errors?.response?.data?.message || errors.toString())
    }

  }


  useEffect(() => {
    console.log('effect')

    const userID = localStorage.getItem('userID')
    const accessToken = localStorage.getItem('accessToken')

    if (userID != undefined && accessToken != undefined) {
      navigate('/userinfo')
      return
    }



    let port = window.location.port == "" ? "" : ":" + window.location.port;

    weburl =
      window.location.protocol +
      "//" +
      window.location.hostname +
      port +
      window.location.pathname;
    line_redirect_uri.value =
      `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=` +
      weburl +
      "&state=12345abcde&scope=profile%20openid&nonce=09876xyz";

    setLineURI(line_redirect_uri.value)

    console.log(line_redirect_uri.value);

    console.log("getLineCode");
    console.log(searchParams.get('code'));

    //usersignin('line',"Uc38f783dd3426dc41071e27f24971e07")

    if (searchParams.get('code') != undefined) {

      getLineIDToken();
      getSystem()
    }

  }, [])




  return <div className="appWidth pt-20 max-w-[600px] ">

    {
      !loading ?
        <div>
          <img className="w-1/2 mx-auto mb-8 rounded-[20px]" src={logoImg} />
          <div className="hidden flex mb-10">

            <div className='w-full '>



              {
                sendData.map((i, index) =>
                  <div className='w-full' key={i.title}>
                    <div className="mb-0.5 w-full flex items-center">
                      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                        {i.title}
                      </div>


                    </div>


                    <div className="relative md:flex bg-main bg-opacity-0 border-solid border border-gray-400 rounded-md w-full mb-6">



                      <div className="w-full  leading-8 text-xl opacity-80" >
                        <input id="inputID" className='px-8 py-4 w-full bg-transparent placeholder-gray-400 text-gray-700' type="text" placeholder={i.placeholder} onChange={(e) => sendDataChange(index, e.target.value)} />
                      </div>

                    </div>
                  </div>
                )}


            </div>


          </div>


          <div className="flex justify-center mb-20">
            <a href={lineURI}>
              <div className="w-60 gradiBtn p-4 specFont mb-4 md:mb-0  shadow-md text-center">Line登入</div>
            </a>
            {/* <button className="w-60 gradiBtn p-4 specFont shadow-md" onClick={() => adminLogin()}>活動方登入</button> */}
          </div>


        </div>
        :
        <div className="flex">

          <p className="m-auto texl-xl text-black">加載中...</p>

        </div>
    }

    {
      showRegisterDiv &&
      <div className="z-[9999]   fixed top-0 left-0 w-full h-screen flex ">
        <div className=" absolute left-0 top-0 w-full h-full bg-cusgray opacity-60">
        </div>
        <div className="text-black z-10 relatvie m-auto w-11/12 max-w-[400px] rounded-lg bg-white p-4 border border-solid border-main">
          <div className="text-center font-bold mb-4">首次登入</div>

          <p className="text-sm ">選擇單位</p>
          <select onChange={(e) => setSelUnitId(Number(e.target.value))} className="w-full border border-solid border-main bg-white rounded-md">
            <option unselectable>請選擇</option>
            {
              unitList.map(i => <option key={i.id} value={i.id}>{i.title}</option>)
            }

          </select>

          <div className="flex mt-4">

            <button onClick={() => userLineCreate()} className="mx-auto w-60 gradiBtn p-4 specFont shadow-md  text-white font-bold  bg-main rounded-md text-center">登入</button>
          </div>
        </div>
      </div>
    }
  </div>
}

export default Login