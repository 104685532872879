
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  Link,
  BrowserRouter as Router,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import { defaultImg, useGlobalFunc } from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import { timestamp2date } from "../../global/constants";
import UserApi from "../../services/UserApi";

const ActiveDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { setLoading } = useGlobalFunc()

  async function getByProductId(selId) {
    setLoading(true)

    try {
      let res = await UserApi.getProductById({ id: selId })
      console.log(res.data)
      let temp = res.data.data.product
      let tempData = [
        {
          title: '標題',
          value: temp.name
        },
        {
          title: '介紹',
          value: temp.description
        },
        {
          title: '價值',
          value: temp.price
        },
        {
          title: '存貨',
          value: temp.amount
        },
      ]
      setDataImage(temp.images.length > 0 ? temp.images[0].url : defaultImg)
      setDataList(tempData)


    }
    catch (errors) {
      console.log(errors?.response?.data?.message || errors.toString())
    }
    setLoading(false)

  }

  async function buyProduct() {
    setLoading(true)
    let json = {
      "productId": parseInt(searchParams.get('id')),
      "amount": 1
    }
    try {
      const res = await UserApi.purchase(json)
      console.log(res)
      alert('兌換成功')
      // window.location.reload()
    }

    catch (errors) {
      let errorMessage = errors?.response?.data?.message || errors.toString()
      console.log(errorMessage)
      if (errorMessage == "not enough time token") {
        alert('你沒有足夠的時間幣')
      }
      else {
        alert('兌換失敗')
      }

    }
    finally {
      getByProductId(searchParams.get('id'))
      setLoading(false)
    }
  }


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');
  const [dataImage, setDataImage] = useState("")
  const [dataList, setDataList] = useState(
    [
    ]
  );


  const [isFisrt, doneFisrt] = useState(false)


  useEffect(() => {
    console.log('effect')

    getByProductId(searchParams.get('id'))


    if (isFisrt == false) {
      doneFisrt(true)
      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }
  }, []);


  return <div className="appWidth py-2" >

    <img className="w-full mb-4 rounded-lg" src={dataImage} />
    {
      dataList.map(i => <div className="border-b border-solid border-gray-400 overflow-hidden ">

        <div className=" col-span-2 px-5 py-3 ">
          <div className="flex flex-col  h-full">
            <div className="h-full">
              <p className="text-gray-500 text-sm mb-1">{i.title}</p>

            </div>
            <div className="text-black flex justify-between text-lg">
              {i.value}

            </div>
          </div>

        </div>


      </div>)

    }

    <div className="grid grid-cols-2 justify-between gap-3 py-4">
      <button className="w-full gradiBtn p-4 specFont shadow-md " onClick={() => buyProduct()} >兌換</button>
      <Link to="/charitytokengift">
        <div className="w-full bg-gray-400 rounded-xl p-4 specFont  shadow-md text-center">取消</div>
      </Link>

    </div>

  </div>

}

export default ActiveDetail