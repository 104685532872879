
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import {defaultImg} from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
const JoinTeam = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const [dataList, setDataList] = useState(
    [
      {
        title: '志願新力量',
        serviceHours: 100,
        ppl: 50,
      },
      {
        title: '志願新力量',
        serviceHours: 100,
        ppl: 50,
      }
    ]
  );


  const [isFisrt, doneFisrt] = useState(false)


  useEffect(() => {
    console.log('effect')

    const auth = localStorage.getItem('auth')


    if (isFisrt == false) {
      doneFisrt(true)


      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }

  }, []);


  return <div className="appWidth py-2" >

    {
      dataList.map(i => <div className="rounded-md border border-solid border-gray-400 overflow-hidden mb-2"><div className="flex justify-between p-4">
        <div className="flex items-center ">
        <img className="w-10 h-10 mr-2 rounded-full" src={defaultImg} />
        <p className="text-black">{i.title}</p>
        </div>
          <button className="bg-main px-2 rounded-md"> 加入</button>
        </div>

        <div className="grid grid-cols-2 p-4">
          <div>
            <p className="text-black text-center"> {i.serviceHours}</p>
            <p className="text-black text-center">服務地圖</p>
          </div>
          <div>
            <p className="text-black text-center"> {i.ppl}</p>
            <p className="text-black text-center">團隊人數</p>
          </div>
          
        </div>

      </div>)

    }


  </div>

}

export default JoinTeam