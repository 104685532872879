
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect,useRef } from 'react';
import {
    BrowserRouter as Router,
    useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Select from 'react-select';

import Modal from 'react-modal';
import { data } from "autoprefixer";

import { timestamp2date } from "../../global/constants";
import moment from 'moment'



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

const EditUserInfo = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);
    const selActivityId = useRef()

    let subtitle;

    const [modalType, setModalType] = useState(0)

    function openModal(type) {
        setModalType(type)
        setIsOpen(true);
    }


    function closeModal(type) {
        setIsOpen(false);
    }

    
    const [sendData, setSendData] = useState([
        {
            title: "ID",
            content: "",
            placeholder: "輸入簽到編號"
        },
        {
            title: "ID",
            content: "",
            placeholder: "輸入完結編號"
        },
    ]);
    const [dataList, setDataList] = useState([
        // {
        //     eventName: "海灘收垃圾",
        //     status: "已結束",
        // },
    ]);
    const loadDataCount = useRef(0)
    const dataValue = useRef([])
    function sendDataChange(index, value) {

        sendData[index].content = value
        setSendData([...sendData])

    }



  function getByUserId() {
      setLoading(true)
    let params = {
      accessToken: localStorage.getItem('accessToken')
    }

    let json = {
      "limit": 100,
      "offset": 0
    }
    const api_url = process.env.REACT_APP_API_URL + "/front/userActivity/search"
    console.log(api_url)

    axios.post(api_url, json, { params: params })
      .then(response => {
          console.log(response.data)

        setDataList(response.data.data.userJoinActivityRecords)
        setLoading(false)

      })
      .catch(error => {
        setLoading(false)
      });
  }

  function quitEvent(selId) {
    let params = {
      accessToken: localStorage.getItem('accessToken'),
      activityId:selId
    }


    const api_url = process.env.REACT_APP_API_URL + "/front/userActivity"
    console.log(api_url)

    axios.delete(api_url, { params: params })
      .then(response => {
          console.log(response.data)

        setDataList([])
        getByUserId()

      })
      .catch(error => {
      });
  }


    function getByActivityId(selId,index) {
   
        let json = {
            "activityId": parseInt(selId),
        }
        const api_url = process.env.REACT_APP_API_URL + "/activity/getByActivityId"
        console.log(json)
    
        axios.post(api_url, json)
            .then(response => {
              loadDataCount.current += 1
              console.log(response.data.data[0])
              console.log(dataValue.current[index])
              dataValue.current[index] = {...dataValue.current[index] , ...response.data.data[0]}
              console.log(dataValue.current)

              
              if (dataValue.current.length == loadDataCount.current){
                //setDataList(dataValue.current)
              }
              
              setDataList( function(prev){
                return prev.map((i,selIndex)=> index == selIndex ? dataValue.current[index] : i )
              })


             
            })
            .catch(error => {
                console.log(error)   
                loadDataCount.current += 1
                setDataList( function(prev){
                    return prev.map((i,selIndex)=> index == selIndex ? dataValue.current[index] : i )
                  })

            });
    }

    function joinFirst() {
   
        let json = {
            "token" : localStorage.getItem('userID'),
            "activityId" : selActivityId.current,
            "pw" : sendData[0].content
        }
        console.log(json)
        const api_url = process.env.REACT_APP_API_URL + "/join/first"
        console.log(api_url)
    
        axios.post(api_url, json)
            .then(response => {
              console.log(response.data)
              resetDataList()
              closeModal(0)
              alert('簽到成功')
            })
            .catch(error => {
                console.log(error)  
                alert('簽到失敗')       
            });
    }

    function joinSecond() {
   
        let json = {
            "token" : localStorage.getItem('userID'),
            "activityId" : selActivityId.current,
            "pw" : sendData[1].content
        }
        console.log(json)
        const api_url = process.env.REACT_APP_API_URL + "/join/second"
        console.log(api_url)
    
        axios.post(api_url, json)
            .then(response => {
              console.log(response.data)
              resetDataList()
              closeModal(1)
              alert('簽到成功')
             
            })
            .catch(error => {
                console.log(error)  
                alert('簽到失敗')         
            });
    }

    function claimToken(selId) {
   
        let json = {
            "token" : localStorage.getItem('userID'),
            "activityId" : selId
        }
        console.log(json)
        const api_url = process.env.REACT_APP_API_URL + "/timetoken/claimTimeToken"
        console.log(api_url)
    
        axios.post(api_url, json)
            .then(response => {
              console.log(response.data)
              resetDataList()

              alert('領取成功')
   
             
            })
            .catch(error => {
                console.log(error)   
                alert('領取失敗')      
            });
    }


    function recordStatus(i){


        if (i.firstCommit === false && Date.now()/1000 < moment(i.activity.endDate).valueOf()){
            return <div className="relative flex items-center">
            <div className="text-main text-left rounded-lg w-full font-bold" >已參加</div>
            <button className="relative pointer-events-auto px-4 py-2  font-bold text-white bg-main text-center rounded-lg w-full cursor-pointer" onClick={()=>[quitEvent(i.activityId)]}>退出活動</button>
            </div> 
        }
        else if (i.firstCommit === true && i.secondCommit === true ){
            return <div className="text-main4 text-left rounded-lg w-full font-bold">已完成</div> 
        }
        else if (i.firstCommit === true && Date.now()/1000 < moment(i.activity.endDate).valueOf()){
            return <div className="text-main text-left rounded-lg w-full font-bold ">已簽到</div> 
        }
        
        else {
            return <div className="text-main3 text-left font-bold  rounded-lg w-full " >已結束</div>
        }
      
      
    }


    function resetDataList(){
        window.location.reload();
    }


    useEffect(() => {
        
        console.log('effect')
        getByUserId()

        navigator.geolocation.getCurrentPosition(
            function(position) {
              console.log(position);
            },
            function(error) {
              console.error("Error Code = " + error.code + " - " + error.message);
            }
          );

        const auth = localStorage.getItem('userID')

        
        if (auth == "normal") {
            navigate('/joinevent')
        }
        else if (auth == "admin") {
            navigate('/hostevent')
        }
    }, [])




    return <div className="appWidth pt-20 max-w-[600px] ">




        <div className="flex mb-10">

            <div className='relative w-full '>
                <div className="mb-0.5 w-full flex items-center">
                    <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                    <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                        活動紀錄
                    </div>

                </div>
               {
                   loading ? <div className="text-center text-black text-xl mt-20">載入中...</div>:""
               }

                {
                    dataList.map((i, index) =>
                    
                        <div className="w-full relative bg-white rounded-[10px] border-solid border-main border p-4 mb-4" key={i.id}>
                        
                            <div className="w-full flex gap-x-4 ">
                                <div className=" w-28  "> 
                                    <img className="w-full aspect-[1/1] mb-2 object-contain" src={i.activity && i.activity.images.length > 0?i.activity.images[0].url:require("../../assets/default_icon.png")} />
                                    <Link to={`/eventdetail?id=${i.activityId}`} className=' ' >
                                        <p className="w-full py-1 text-white text-sm bg-main4 font-bold text-center rounded-[4px]">查看</p>
                                    </Link>

                                </div>

                                <div className="text-black  w-full ">
                                    <p className="mb-1 text-md text-black">{i.activity.title}</p>
                                    <p className="mb-2 text-sm text-main3">{i.activity.address}</p>
                                    <p className=" text-sm font-bold text-main"> 開始 : {moment(i.activity.startdate * 1000).format("YYYY-MM-DD HH:mm")}</p>
                                    <p className="text-sm font-bold text-main mb-1"> 結束 : {moment(i.activity.endDate * 1000).format("YYYY-MM-DD HH:mm")}</p>

                                     <div className="w-full">
                                         {
                                           recordStatus(i)
                                         }
                                    
                                    </div>
                                </div>
                            </div>

                        </div>
            
                    )
                }

                


            </div>

            <Modal
                isOpen={modalIsOpen}

                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"

            >
                <button className="text-black float-right" onClick={closeModal}>關閉 X</button>
                {
                    modalType == 0?
                    <div>
                        <h2 className="text-black text-2xl mb-4" ref={(_subtitle) => (subtitle = _subtitle)}>活動開始簽到</h2>

                        <input id="inputID" className='px-2 py-[5px] w-full  border border-solid border-gray-400 rounded-lg placeholder-gray-400 text-black mb-6' type="text" placeholder={sendData[0].placeholder} onChange={(e) => sendDataChange(0, e.target.value)} />
                        <button className="text-white font-bold gradiBtn px-4 py-2" onClick={()=>joinFirst()}>確定</button>

                    </div>
                    :
                    <div>
                        <h2 className="text-black text-2xl mb-4" ref={(_subtitle) => (subtitle = _subtitle)}>活動完結簽到</h2>

                        <input id="inputID" className='px-2 py-[5px] w-full  border border-solid border-gray-400 rounded-lg placeholder-gray-400 text-black mb-6' type="text" placeholder={sendData[0].placeholder} onChange={(e) => sendDataChange(1, e.target.value)} />
                        <button className="text-white font-bold gradiBtn px-4 py-2" onClick={()=>joinSecond()} >確定</button>

                    </div>

                }

            </Modal>


        </div>






    </div>
}

export default EditUserInfo