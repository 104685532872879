import { menuData } from "../global/constants"
import { useState ,useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import homeImg from '../assets/home.png';
import activeImg from '../assets/active.png';
import userImg from '../assets/user.png';
import homeSelImg from '../assets/homeSel.png';
import activeSelImg from '../assets/activeSel.png';
import userSelImg from '../assets/userSel.png';


const Header = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation()

  const changeLanguage = () => {
    console.log(i18n.language)
    if (i18n.language == 'zh-TW') {
      i18n.changeLanguage('en');
    }
    else {
      i18n.changeLanguage('zh-TW');
    }
  };

  const [tabIndex, setTabIndex] = useState(0)

  const [showStoryMenu, setShowStoryMenu] = useState(false)


  function tabSelect(){
    if (location.pathname == "/home" || 
        location.pathname == "/charitytokengift" ||
        location.pathname == "/charitytokentransfer" ||
        location.pathname == "/charitytokendonate" ||
        location.pathname == "/eventdetail"||
        location.pathname == "/joinevent"  ){
      return setTabIndex(0)
    }
    else if  (location.pathname == "/article" ||
              location.pathname == "/articledetail"){
      return setTabIndex(1)
    }
    else{
      return setTabIndex(2)
    }

  }

  useEffect(() => {
    tabSelect()
   
  }, [location.pathname]);


  return <div className=" relative  w-full">
    <div className="h-[80px]"></div>
    <div className="appWidth w-full fixed bottom-6 left-1/2 transform -translate-x-1/2 px-4 z-50" >
    <div className="bg-white text-[#A8A8A8] w-full  h-[60px] rounded-full p-1 mx-auto" style={{ boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.13)" }}>
      <div className="grid grid-cols-3 justify-around items-center h-full">
        <Link to={'/home'} className={`${tabIndex == 0? 'bg-main text-white':''} flex h-full items-center justify-center rounded-full `} >
          <div>
            <img className=" w-5 h-5 object-contain mx-auto" src={tabIndex== 0 ? homeSelImg:homeImg} alt="" />
            <p className="text-center text-sm font-semibold mt-0.5">首頁</p>
          </div>
        </Link>
        <Link to={'/article'} className={`${tabIndex == 1? 'bg-main text-white':''} flex h-full   items-center justify-center rounded-full  `} >
          <div>
            <img className=" w-5 h-5 object-contain mx-auto" src={tabIndex== 1 ? activeSelImg:activeImg} alt="" />
            <p className="text-center text-sm font-semibold mt-0.5">動態</p>
          </div>
        </Link>
        <Link to={'/userinfo'} className={`${tabIndex == 2? 'bg-main text-white':''} flex h-full   items-center justify-center rounded-full `} >
          <div>
            <img className=" w-5 h-5 object-contain mx-auto " src={tabIndex== 2 ? userSelImg:userImg} alt="" />
            <p className="text-center text-sm font-semibold mt-0.5">我的</p>
          </div>
        </Link>

      </div>
    </div>


    </div>


  </div>

}
export default Header