
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,
  Link

} from "react-router-dom";
import { defaultImg, timestamp2date, gEventArea, getEventType, useGlobalFunc } from '../../global/constants'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
import Select from 'react-select';
import { assertNodeList } from "react-modal/lib/helpers/ariaAppHider";
import UserApi from "../../services/UserApi";
const JoinEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setLoading } = useGlobalFunc()




  const eventRegionOption = [
    { value: '台北市', label: '台北市' },
    { value: '新北市', label: '新北市' },
    { value: '桃園市', label: '桃園市' },
    { value: '新竹縣市', label: '新竹縣市' },
    { value: '台中', label: '台中' },
    { value: '台南', label: '台南' },
    { value: '高雄', label: '高雄' },
    { value: '花蓮', label: '花蓮' },
    { value: '台東', label: '台東' },
    { value: '外島', label: '外島' },
  ]

  const eventTypeOption = [
    { value: '社區服務', label: '社區服務' },
    { value: '文教服務', label: '文教服務' },
    { value: '流浪犬照顧', label: '流浪犬照顧' },
    { value: '淨灘社福', label: '淨灘社福' },
    { value: '志工', label: '志工' },
  ]


  const [eventRegion, setEventRegion] = useState(eventRegionOption[0].value)
  const [eventType, setEventType] = useState(eventTypeOption[0].value)




  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState('');

  const [dataList, setDataList] = useState(
    []
  );

  const dataValue = useRef([])

  const [selUsed, setSelUsed] = useState(false);
  const [showUseSuccess, setShowUseSuccess] = useState(false);

  async function getOrder() {

    let json = {
      "limit": 100,
      "offset": 0,
      "filter": {
        "used": [selUsed
        ]
      }
    }


    console.log(json)
    try {

      const res = await UserApi.purchaseSearch(json)

      console.log(res.data.data.userPurchases)
      setDataList(res.data.data.userPurchases)


      let selIndex = dataValue.current.length
      dataValue.current = dataList.concat(res.data.data.userPurchases)
      console.log(dataValue.current)


      for (let i = 0; i < dataValue.current.length; i++) {
        console.log(dataValue.current.length)
        console.log(dataValue.current[selIndex])
        if (dataValue.current[i].from_activity != null) {
          //getByEventId(dataValue.current[i].from_activity,i)
        }
      }
    }
    catch (errors) {
      console.log(errors?.response?.data?.message || errors.toString())
    }
  }

  async function purchaseUse(item) {

    setLoading(true)
    let json = {
      userPurchasesId: Number(item.id)
    }
    console.log(json)
    try {
      const res = await UserApi.purchaseUse(json)
      setShowUseSuccess(item)
      getOrder()

    }
    catch (errors) {
      console.log(errors?.response?.data?.message || errors.toString())
    }
    finally {
      setLoading(false)
    }
  }



  const [isFisrt, doneFisrt] = useState(false)

  async function getInitData() {
    console.log(await getEventType())
  }


  useEffect(() => {
    console.log('effect')


    const auth = localStorage.getItem('auth')
    getInitData()



    if (isFisrt == false) {
      doneFisrt(true)
      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }
  }, []);


  useEffect(() => {

    getOrder()

  }, [selUsed]);


  return <div className="appWidth py-4" >

    {/* <Select
    defaultValue={eventRegionOption[0]}
    options={eventRegionOption}
    className="basic-multi-select mb-2 text-black"
    classNamePrefix="select"
    onChange={(e)=>setEventRegion(e.value)}
  />

  <Select
    defaultValue={eventTypeOption[0]}
    options={eventTypeOption}
    className="basic-multi-select mb-4 text-black"
    classNamePrefix="select"
    onChange={(e)=>setEventType(e.value)}
  /> */}

    <div className="mb-0.5 w-full flex items-center ">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className="flex items-center gap-4 px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        兌換紀錄
        <div className="flex text-sm gap-2 rounded-md bg-main p-1">
          <div onClick={() => setSelUsed(false)} className={`${!selUsed ? 'bg-white text-main' : "text-white"} px-2 py-1 cursor-pointer font-bold rounded-md`}>
            持有中
          </div>
          <div onClick={() => setSelUsed(true)} className={`${selUsed ? 'bg-white text-main' : "text-white"}  px-2 py-1 cursor-pointer font-bold rounded-md`}>
            已使用
          </div>

        </div>
      </div>

    </div>
    <div className="w-full  min-w-[400px]" >
      {
        dataList.map(i => <div className={`${!i.used ? "bg-white text-black" : "bg-gray-400 text-black"}  shadow-me w-full rounded-md p-4 mb-4`}>
          <p className="mb-1 text-black font-bold">{i.product.name}</p>
          <p className="mb-1">{i.product.description}</p>
          <p className="mb-1">{i.product.type}</p>
          <p className="">{i.product.address}</p>

          {
            !i.used &&
            <button onClick={() => purchaseUse(i)} className="mt-2 w-40 gradiBtn p-4 specFont shadow-md">使用</button>

          }

        </div>)

      }

    </div>
    {/* <div className="w-full min-h-screen overflow-x-scroll">
      <table className="w-full  min-w-[400px]" >
        <thead>
          <tr className="text-gray-600 text-lg text-left border-b border-solid border-gray-400">
            <th className="w-36">日期時間</th>
            <th className="w-40">說明</th>
            <th className="min-w-10">數量</th>
          </tr>
        </thead>
        <tbody>


          {
            dataList.map(i => <tr key={i.id} >

              <td className="text-black text-sm ">{timestamp2date(i.createdAt)}</td>
              <td className="text-black text-lg ">{i.transaction_info.description}</td>
              <td className="text-black text-lg">{i.transaction_info.value}</td>

            </tr>)

          }

        </tbody>
      </table>
    </div> */}

    {
      showUseSuccess !== false &&
      <div className="z-[9999]   fixed top-0 left-0 w-full h-screen flex ">
        <div className=" absolute left-0 top-0 w-full h-full bg-cusgray opacity-60">
        </div>
        <div className="text-black z-10 relatvie m-auto w-11/12 max-w-[400px] rounded-lg bg-white p-4 border border-solid border-main">
          <div className="text-center font-bold mb-4">使用成功</div>

          <p className="mb-1">{showUseSuccess.product.name}</p>
          <p className="mb-1">{showUseSuccess.product.description}</p>
          <p className="mb-1">{showUseSuccess.product.type}</p>
          <p className="">{showUseSuccess.product.address}</p>

          <div className="flex mt-4">
            <button onClick={() => setShowUseSuccess(false)} className="mx-auto w-60 gradiBtn p-4 specFont shadow-md  text-white font-bold  bg-main rounded-md text-center">確定</button>
          </div>
        </div>
      </div>
    }


  </div>

}

export default JoinEvent