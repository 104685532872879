import { menuData } from "../global/constants"
import { useState,useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Navigate,
    useNavigate,
    useLocation
} from "react-router-dom";

import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux';


const Header = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation()

    const changeLanguage = () => {
        console.log(i18n.language)
        if (i18n.language == 'zh-TW') {
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage('zh-TW');
        }

    };

    const [showStoryMenu, setShowStoryMenu] = useState(false)

    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }

     async function getUserInfo() {
        let params = {
            accessToken: localStorage.getItem('accessToken')
        }

        let api_url = process.env.REACT_APP_API_URL + "/front/user";
        try {
            const response = await axios.get(api_url, { params: params });
            console.log(response.data.data.user);

            dispatch({
                type: "UPDATE_USERINFO",
                payload: { userInfo: response.data.data.user }
            });

            //gUserInfo = response.data.data.userInfo

        } catch (error) {
            console.error(error);
            localStorage.removeItem('userID')
            localStorage.removeItem('accessToken')
            navigate('/login')
        }
    }

    async function getSystem() {
        let params = {
            accessToken: localStorage.getItem('accessToken')
        }

        let api_url = process.env.REACT_APP_API_URL + "/front/system";
        try {
            const response = await axios.get(api_url, { params: params });
            console.log(response.data.data);

            let tempAritcleType = response.data.data.blogTypes
            let tempArea = response.data.data.activityAreas
            let tempType = response.data.data.activityTypes
            let system = response.data.data.userInfo[0]
            
    
            tempAritcleType.forEach(i => (i.value = i.title,i.label=i.title));
            tempArea.forEach(i => (i.value = i.title,i.label=i.title));
            tempType.forEach(i => (i.value = i.title,i.label=i.title));


            dispatch({
                type: "UPDATE_SYSTEM",
                payload: { articleType:tempAritcleType , eventArea: tempArea,eventType: tempType ,system:system }
            });

            //gUserInfo = response.data.data.userInfo

        } catch (error) {
            console.error(error);
        }
    }



  useEffect(() => {
    if (location.pathname === '/qrcodereader'){

    }
    else if (localStorage.getItem('userID') == undefined || localStorage.getItem('accessToken') == undefined){
      navigate('/login')
    }
    else{
      getUserInfo()
      getSystem()
    }
}, [location.pathname]);
  

    return <div className="relative z-50 w-full">
        <div className="h-[64px]"></div>

        <div className="bg-main fixed  left-0 top-0 z-50 w-full h-16">
            <Link to={'/home'} className="flex text-2xl font-semibold appWidth absolute  left-1/2 transform -translate-x-1/2 top-0  h-[64px] items-center justify-center " >
                時間銀行
        </Link>
        </div>



    </div>

}
export default Header