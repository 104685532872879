
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    useNavigate,

} from "react-router-dom";

import { gUserInfo, getUserInfo } from '../../global/constants.js'
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Select from 'react-select';

import { defaultImg } from "../../global/constants.js";

const EditUserInfo = () => {
    const navigate = useNavigate();

    const userID = localStorage.getItem('userID')



    const [sendData, setSendData] = useState([
        {
            title: "系統ID",
            content: "",
            placeholder: "輸入系統ID"
        },
    ]);
    const [familyList, setFamilyList] = useState([

    ]);
    function sendDataChange(index, value) {

        sendData[index].content = value
        setSendData([...sendData])

    }


    //   function getTokenRecord() {
    //     let params = {
    //       accessToken: localStorage.getItem('accessToken')
    //     }
    //     let json = {
    //       "limit": 100,
    //       "offset": 0
    //     }
    //     const api_url = process.env.REACT_APP_API_URL + "/front/transfer/search"
    //     console.log(api_url)

    //     axios.post(api_url, json, { params: params })
    //       .then(response => {

    //         console.log(response.data)
    //         setDataList(dataList.concat(response.data.data.timeTokenRecords))

    //       })
    //       .catch(error => {
    //         console.log(error)
    //       });
    //   }

    //     function getFriend12() {

    //         let json = {
    //             "token" : localStorage.getItem('userID')
    //         }
    //         const api_url = process.env.REACT_APP_API_URL + "/friend/get"
    //         console.log(json)

    //         axios.post(api_url, json)
    //             .then(response => {
    //                 console.log(response.data)
    //                 setFamilyList(response.data.data)

    //                 for (let i = 0; i < response.data.data.length; i++) {

    //                         getByUserId(response.data.data[i].slave_id,i)

    //                   }  

    //             })
    //             .catch(error => {
    //                 //this.setState({ errorMessage: error.message });

    //             });
    //     }

    //     function getByUserId21(selId,index) {
    //         let json = {
    //           "userId": parseInt(selId),
    //         }
    //         const api_url = process.env.REACT_APP_API_URL + "/user/getByUserId"
    //         console.log(json)

    //         axios.post(api_url, json)
    //           .then(response => {
    //             console.log(response.data)

    //             setFamilyList( function(prev){
    //               return prev.map((i,selIndex)=> index == selIndex ? response.data.data : i )
    //             })


    //           })
    //           .catch(error => {
    //             console.log(error)



    //           });
    //       }

    //     function createFriend2() {

    //         let json = {
    //             "token" : localStorage.getItem('userID'),
    //             "friendSystemId" : sendData[0].content
    //         }
    //         const api_url = process.env.REACT_APP_API_URL + "/friend/create"
    //         console.log(json)

    //         axios.post(api_url, json)
    //             .then(response => {
    //                 getFriend()
    //             })
    //             .catch(error => {
    //                 //this.setState({ errorMessage: error.message });
    //                 alert('系統編號輸入錯誤')

    //             });
    //     }

    function getFriend() {
        let params = {
            accessToken: localStorage.getItem('accessToken')
        }
        let json = {
            "limit": 100,
            "offset": 0
        }
        const api_url = process.env.REACT_APP_API_URL + "/front/friend"
        console.log(api_url)

        axios.get(api_url, { params: params })
            .then(response => {


                console.log(response.data)
                setFamilyList(response.data.data.userFriendships)

            })
            .catch(error => {
                console.log(error)
            });
    }

    function createFriend() {
        let params = {
            accessToken: localStorage.getItem('accessToken')
        }
        let json = {
            "systemId": sendData[0].content
        }
        const api_url = process.env.REACT_APP_API_URL + "/front/friend"
        console.log(api_url)

        axios.post(api_url, json, { params: params })
            .then(response => {
                getFriend()

            })
            .catch(error => {
                alert('查無此系統ID的用戶')
            });
    }

    function delFriend(selId) {
        let params = {
            accessToken: localStorage.getItem('accessToken'),
            "systemId": selId
        }
        let json = {
           
        }
        const api_url = process.env.REACT_APP_API_URL + "/front/friend"
        console.log(api_url)

        axios.delete(api_url, { params: params })
            .then(response => {
                
                
                getFriend()

            })
            .catch(error => {
                alert('刪除失敗')
            });
    }



    useEffect(() => {
        console.log('effect')

        getFriend()


    }, [])




    return <div className="appWidth pt-20 max-w-[600px] ">


        <div className="flex mb-10">

            <div className='w-full '>


                {
                    sendData.map((i, index) =>
                        <div className='w-full' key={i.title}>
                            <div className="mb-0.5 w-full flex items-center">
                                <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                                <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5" >
                                    新增家人
                                </div>
                            </div>

                            <div className=" overflow-hidden rounded-md  relative md:flex bg-white border-solid border border-[#CCCCCC]  w-full mb-6">

                                <div className="w-full  text-base opacity-80 " >
                                    <input id="inputID" className='px-2 py-[5px] w-full   placeholder-gray-400 text-black' type="text" placeholder={i.placeholder} onChange={(e) => sendDataChange(index, e.target.value)} />
                                </div>

                            </div>
                        </div>
                    )}

                <button className="w-60 gradiBtn p-4 specFont mb-4 md:mb-0 mr-4 shadow-md" onClick={() => createFriend()}>新增</button>


            </div>


        </div>

        <div className="flex mb-10">

            <div className='w-full '>
                <div className="mb-0.5 w-full flex items-center">
                    <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                    <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                        我的家人
                                </div>

                </div>


                {
                    familyList.map((i, index) =>
                        <div className=' w-full bg-main bg-opacity-70 p-4 rounded-md mb-4' key={i.title}>
                            <div className="flex items-center">
                                <div className="flex-none w-28 h-28 flex items-center rounded-md overflow-hidden">
                                    <img className="w-full h-full object-cover" src={i.icon ? i.icon : defaultImg} />

                                </div>

                                <div className="text-black p-3">

                                    <p>真實名稱: {i.user.name}</p>
                                    <p className="mb-2">電話: {i.user.phone}</p>
                                    <p className=" text-sm text-gray-800 mb-1">地址: {i.user.address}</p>
                                    <div onClick={()=>delFriend(i.user.systemId)} className="bg-main cursor-pointer rounded-[4px] px-4 py-1 font-bold text-white">
                                        刪除家人
                                </div>
                                </div>
                            </div>
                        </div>
                    )}


            </div>


        </div>






    </div>
}

export default EditUserInfo