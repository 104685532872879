
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    useNavigate,
    Link

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";

import Select from 'react-select';
import { getUserInfoById } from '../../global/constants.js'

import Compressor from 'compressorjs';

import default_icon from '../../assets/upload_img.jpg';
import { data } from "autoprefixer";

import { useSelector, useDispatch } from 'react-redux';

const EditUserInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userInfo);

    const [loading, setLoading] = useState(false);

    const sexOption = [
        { id: 0, value: '不詳', label: '不詳' },
        { id: 1, value: '男', label: '男' },
        { id: 2, value: '女', label: '女' },
    ]

    const unitOption = [
        { id: 0, value: '中原志工社', label: '中原志工社' },
        { id: 1, value: '元智志工社', label: '元智志工社' },
        { id: 2, value: '中央志工社', label: '中央志工社' },
        { id: 3, value: '其他', label: '其他' },
    ]



    const [sex, setSex] = useState(undefined)
    const [unit, setUnit] = useState(undefined)

    const [compressedFile, setCompressedFile] = useState(null);

    function callInputImg() {
        let call = document.querySelector('#inputimg')
        call.click()
    }

    const handleCompressedUpload = (e) => {
        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            convertSize: 2000000,
            success: (result) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server. 
                console.log(result)
                setCompressedFile(URL.createObjectURL(result))

                var reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    //console.log(base64data);
                    //base64data = base64data.split(',')[1]

                    imgBase64.current = base64data

                    console.log(base64data)

                }
            },
        });
    };
    const imgBase64 = useRef('')


    const [sendData, setSendData] = useState([

    ]);
    function sendDataChange(index, value) {

        sendData[index].content = value
        setSendData([...sendData])

    }

    async function updateUserInfo() {
        setLoading(true)
        if (imgBase64.current != "") {
            await updateIcon()
        }
        let params = {
            accessToken: localStorage.getItem('accessToken')
        }
        console.log(unit)
        let json = {
            "nickName": sendData[0].content == undefined ? "" : sendData[0].content,
            "name": sendData[1].content == undefined ? "" : sendData[1].content,
            "sex": sex == undefined ? 0 : sex.id,
            "address": sendData[3].content == undefined ? "" : sendData[3].content,
            "phone": sendData[2].content == undefined ? "" : sendData[2].content,
            "unit": unit == undefined ? 0 : unit.value,
        }
        const api_url = process.env.REACT_APP_API_URL + "/front/user"
        console.log(json)

        axios.patch(api_url, json, { params: params })
            .then(response => {

                console.log(response.data)

                dispatch({
                    type: "UPDATE_USERINFO",
                    payload: { userInfo: { ...userInfo, ...json } }
                });

                navigate('/userinfo')
                //window.location.reload()
                setLoading(false)


            })
            .catch(error => {
                console.log(error)
                alert('更新失敗')
                //window.location.reload()
                setLoading(false)

            });
    }

    async function updateIcon() {
        let params = {
            accessToken: localStorage.getItem('accessToken')
        }
        let json = {
            "imageBase64": imgBase64.current
        }

        const api_url = process.env.REACT_APP_API_URL + "/front/user/image"
        console.log(json)

        if (userInfo.images.length > 0) {
            let delparams = {
                accessToken: localStorage.getItem('accessToken'),
                "url": userInfo.images[0].url
            }
            await axios.delete(process.env.REACT_APP_API_URL + "/front/user/image", { params: delparams })
        }
        console.log('ss')


        return await axios.post(api_url, json, { params: params })

    }

    useEffect(() => {
        console.log(userInfo)
        if (userInfo != undefined) {
         

            setCompressedFile(userInfo.images.length > 0 ? userInfo.images[0].url : null)
            setSex( userInfo.sex != undefined ? sexOption[userInfo.sex] : sexOption[0])
            setUnit(userInfo.unit != undefined ? unitOption.find(i=>i.value ==userInfo.unit) : unitOption[0])
            setSendData([
                {
                    title: "暱稱",
                    content: userInfo.nickName,
                    placeholder: "暱稱"
                },
                {
                    title: "真實姓名",
                    content: userInfo.name,
                    placeholder: "真實姓名"
                },
                {
                    title: "手機號碼",
                    content: userInfo.phone,
                    placeholder: "手機號碼"
                },
                // {
                //     title: "身份證號碼",
                //     content: "",
                //     placeholder: "身份證號碼"
                // },
                {
                    title: "地址",
                    content: userInfo.address,
                    placeholder: "地址"
                },
            ]);
        }

    }, [userInfo])




    return <div className="appWidth pt-20 max-w-[600px]">

        <div className="mb-4 w-full flex items-center">
            <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
            <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                修改個人資料
                    </div>

        </div>

        {
            !loading ?
                <>

                    <div className="w-60 mx-auto mb-2">
                        <div className="w-full ratio-1/1 rounded-md overflow-hidden shadow-md">
                            <div className="ratio-in  pointer-events-none ">
                                <img src={compressedFile ? compressedFile : default_icon} className=" absolute  left-0 top-0 w-full h-full object-cover"
                                />
                            </div>
                            <input
                                className="hidden"
                                id="inputimg"
                                accept="image/*"
                                type="file"
                                onChange={(event) => handleCompressedUpload(event)}
                            />
                            <div
                                type="button"
                                className="absolute top-0 left-0 w-full h-full bg-text cursor-pointer"
                                onClick={() => callInputImg()}
                            >
                            </div>


                        </div>
                    </div>
                    <p className="text-center text-black mb-4">選擇圖片</p>



                    <div className="flex ">

                        <div className='w-full '>

                            <div className="mb-0.5 w-full flex items-center">
                                <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                                <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                                    性別
                    </div>

                            </div>


                            <Select
                                defaultValue={sexOption[0]}
                                options={sexOption}
                                className="basic-select mb-4 text-black border-gray-700"
                                classNamePrefix="select"
                                onChange={(e) => setSex(e)}
                                value={sex}
                            />


                            {
                                sendData.map((i, index) =>
                                    <div className='w-full' key={i.title}>
                                        <div className="mb-0.5 w-full flex items-center">
                                            <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                                            <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                                                {i.title}
                                            </div>

                                        </div>

                                        <div className=" overflow-hidden rounded-md  relative md:flex bg-white border-solid border border-[#CCCCCC]  w-full mb-6">

                                            <div className="w-full  text-base opacity-80 " >
                                                <input id="inputID" className='px-2 py-[5px] w-full   placeholder-gray-400 text-black' type="text" placeholder={i.placeholder} value={i.content} onChange={(e) => sendDataChange(index, e.target.value)} />
                                            </div>

                                        </div>
                                    </div>
                                )}


                            <div className="mb-0.5 w-full flex items-center">
                                <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                                <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                                    單位
                    </div>

                            </div>
                            <Select
                            menuPlacement="top"
                                defaultValue={unitOption[0]}
                                options={unitOption}
                                className="basic-select mb-4 text-black border-gray-700"
                                classNamePrefix="select"
                                onChange={(e) => setUnit(e)}
                                value={unit}
                            />


                        </div>


                    </div>


                    <div className="grid grid-cols-2 justify-between gap-3 py-4">
                        <button className="w-full gradiBtn p-4 specFont  shadow-md" onClick={() => updateUserInfo()} >修改</button>
                        <Link to="/userinfo" className="w-full text-center bg-gray-400 rounded-xl p-4 specFont shadow-md" >取消</Link>
                    </div>
                </>
                : <p className="text-center text-black">載入中...</p>
        }



    </div>
}

export default EditUserInfo