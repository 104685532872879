
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,

} from "react-router-dom";
import axios from "axios";
import { format } from "prettier";
import { LazyResult } from "postcss";
const JoinTraining = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [userInfo, setUserInfo] = useState({});

  const [eventInfo, setEventInfo] = useState({});

  const [joinCode, setJoinCode] = useState('');
  const [endCode, setEndCode] = useState('');

  const [point, setPoint] = useState(`時間銀行是一種以時間作為貨幣的社區服務交換平台，通過志願者提供自己的時間和技能，互相幫助，實現共同成長。
  
  為了推廣時間銀行，我們可以採取以下推廣方法：
  舉辦宣傳活動：可以通過舉辦各種宣傳活動，如論壇、研討會、展覽會等，向社會大眾介紹時間銀行的理念和優勢，吸引更多的志願者加入其中。
  建立社區合作夥伴：可以與當地社區組織、非營利機構和政府機構建立合作夥伴關係，共同推廣時間銀行，提高其知名度和影響力。
  利用社交媒體：可以通過各種社交媒體平台，如微信、微博、Facebook等，發佈時間銀行的最新資訊、志願者故事和推廣活動，吸引更多人關注和參與其中。
  提供專業培訓：可以為志願者提供專業培訓，提高他們的技能和能力，增強時間銀行的服務品質和效益。
  建立網絡平台：可以建立一個專門的網絡平台，為志願者提供便捷的交流和服務渠道，增強志願者之間的互動和信任。
  總之，推廣時間銀行需要採取多種方式，包括舉辦宣傳活動、建立社區合作夥伴、利用社交媒體、提供專業培訓和建立網絡平台等，從而吸引更多的志願者加入其中，實現更多的社區服務和共同成長。


  如果你想成為一名志願者，加入時間銀行，請看以下步驟：
  了解時間銀行理念：時間銀行是一個互助共享的社區，志願者可以用自己的時間來幫助需要幫助的人，同時也可以得到其他志願者的幫助。
  註冊時間銀行帳戶：請註冊一個時間銀行帳戶，填寫個人信息和聯繫方式等，方便與其他志願者和用戶聯繫。
  選擇服務項目：時間銀行有各種不同的服務項目，您可以根據自己的專長和興趣選擇適合自己的服務項目，例如陪伴老人、幫助學習、照顧寵物等。
  預約服務時間：在時間銀行平台上預約服務時間，確保您的服務時間和受助人的時間一致，減少不必要的時間浪費。
  執行服務任務：按照預約時間和服務項目，按時前往服務地點，並完成服務任務。
  積累時間銀行積分：完成服務任務後，您可以獲得相應的時間銀行積分，這些積分可以用來換取其他志願者的幫助。


  加入時間銀行，您可以得到以下好處：
  培養社會責任感：成為時間銀行志願者，您可以用自己的時間來幫助需要幫助的人，這樣可以增強您的社會責任感和公益意識。
  學習新技能：通過參與時間銀行的服務活動，您可以學習新的技能和知識，提高自己的專業水平和能力。
  擴大人脈：時間銀行是一個社區共享的平台，您可以通過參與服務活動，認識更多志同道合的人，擴大自己的社交圈子。
  積累時間銀行積分：完成服務任務後，您可以獲得相應的時間銀行積分，這些積分可以用來換取其他志願者的幫助，實現。


  歡迎加入時間銀行！作為時間銀行的一員，您可以為社區做出貢獻，同時獲得以下好處：
  增加社交網絡：透過時間銀行，您可以認識更多志同道合的人，擴展社交圈子。
  學習新技能：時間銀行提供各種志願服務機會，您可以學習新的技能，增加自己的能力。
  增強社區凝聚力：通過時間銀行的志願服務，您可以幫助社區內的人們，加強社區的凝聚力。
  活躍身心：參與志願服務有助於活躍身心，增加對生活的熱情。
  獲得時間幣：每次完成志願服務，您可以獲得對應的時間幣，以便您在需要的時候，向其他時間銀行會員尋求幫助。
  加入時間銀行，您可以不僅幫助他人，也能為自己帶來更多的收穫。趕快加入我們，一起為社區服務吧！
`);


  const [isFisrt, doneFisrt] = useState(false)


  useEffect(() => {
    console.log('effect')

    const auth = localStorage.getItem('auth')


    if (isFisrt == false) {
      doneFisrt(true)


      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }

  }, []);


  return <div className="appWidth py-20" >

    <div className="mb-0.5 w-full flex items-center">
      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
      <div className=" px-0 py-2 font-bold text-xl  text-gray-800 bg-main bg-opacity-0 mb-0.5">
        關於我們
      </div>

    </div>

    <div className="text-black">

    </div>

    <div className=" text-black" dangerouslySetInnerHTML={{ __html: `<div>${point.replaceAll('\n', "<br/>")}</div>` }}>

    </div>


  </div>

}

export default JoinTraining