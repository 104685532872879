
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useNavigate,
  Link

} from "react-router-dom";
import axios from "axios";

import { timestamp2date } from "../../global/constants";

import { format } from "prettier";
import { LazyResult } from "postcss";
import { defaultImg } from '../../global/constants'

import serviceImg from '../../assets/service.png'

import { useSelector, useDispatch } from 'react-redux';
import UserApi from "../../services/UserApi";

const JoinTraining = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userInfo = useSelector(state => state.userInfo);


  const [giftList, setGiftList] = useState(
    [

    ]
  );


  async function getMarket() {
    //setLoading(true)
    let json = {
      "limit": 100,
      "offset": 0,
      "filter": {
        "type": [
        ]
      }
    }
    try {
      let res = await UserApi.productSearch(json)
      console.log(res.data.data.products)
      setGiftList(res.data.data.products)

    }
    catch (errors) {
      console.log(errors?.response?.data?.message || errors.toString())
    }
    //setLoading(false)

  }


  const [isFisrt, doneFisrt] = useState(false)


  useEffect(() => {
    console.log('effect')


    getMarket()

    if (isFisrt == false) {
      doneFisrt(true)


      //   console.log(isFisrt)
      //   if (scrollToId != null ){
      //       scroller.scrollTo(scrollToId ,{smooth:true,offset:-150})
      //    }

    }

  }, []);


  return <div className=" py-8" >
    {
      userInfo ? <>
        <div className="appWidth flex items-center gap-x-2 mb-8">
          <img className="w-6 object-contain " src={serviceImg} />
          <p className=" text-main2 text-base sm:text-xl font-bold">服務兌換</p>
        </div>

        <p className=" text-main4 text-base font-bold text-center mb-8">我的時間幣：{userInfo.timeToken}</p>

        <div className="appWidth w-full rounded-[60px] min-h-screen   px-6 py-9  overflow-hidden ">


          <div className="w-full rounded-[60px] bg-white py-9 px-6  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-6  overflow-hidden ">
            {
              giftList.map(i =>

                <Link to={`/charitytokengiftdetail?id=${i.id}`} className=" col-span-1 " key={i.id}>


                  <img className="w-full bg-gray-200 aspect-[1/1] object-contain rounded-xl mb-2" src={i.images.length > 0 ? i.images[0].url : defaultImg} />


                  <p className="text-gray-600">{i.name}</p>

                  <div className="flex justify-between">
                    <p className="text-main3 text-sm">{i.price} 時間幣</p>
                    <p className="text-main3 text-sm"> 存貨 {i.amount}</p>
                  </div>



                </Link>



              )

            }
          </div>
        </div>


      </> : ""
    }
  </div>

}

export default JoinTraining