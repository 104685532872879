import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import Header from './compontents/Header'
import Footer from './compontents/Footer'


import JoinEvent from './pages/JoinEvent'
import EventDetail from './pages/EventDetail'
import HostEvent from './pages/HostEvent'
import JoinTraining from "./pages/JoinTraining"
import JoinTeam from "./pages/JoinTeam"
import CharityRecord from "./pages/CharityRecord"
import CharityTokenTransfer from "./pages/CharityTokenTransfer"
import CharityTokenDonate from "./pages/CharityTokenDonate"
import CharityTokenGift from "./pages/CharityTokenGift"
import CharityTokenGiftDetail from "./pages/CharityTokenGiftDetail"
import ServiceMap from "./pages/ServiceMap"
import CharityStory from "./pages/CharityStory"
import JobList from "./pages/JobList"
import UserInfo from "./pages/UserInfo"
import EditUserInfo from "./pages/EditUserInfo"
import Article from "./pages/Article"
import ArticleDetail from "./pages/ArticleDetail"
import AboutUs from "./pages/AboutUs"
import ShareQRcode from "./pages/ShareQRcode"
import MyFamily from "./pages/MyFamily"
import EventRecord from "./pages/EventRecord"
import GiftRecord from "./pages/CharityTokenGiftOrderRecord"
import QRCodeReader from "./pages/QRCodeReader"

import PassBookRecord from "./pages/PassBookRecord"

import Login from "./pages/Login";

import Home from "./pages/Home";

import "./i18n"

import { useTranslation } from "react-i18next";
import { t } from "react-i18next";


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import RouteChange from './compontents/RouteChange'

import { Provider } from "react-redux";
import { store } from "./model/store.js";
import Loading from './compontents/Loading';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter >
      <div className='wholebg grid grid-cols-1 w-full h-full min-h-screen  mx-auto'>
        <ScrollToTop />
        <RouteChange />
        <Loading />

        <div className='relative w-full  grid-cols-none'>
          <Header />
          <div className="relative">
            <Routes >
              <Route path="*" element={<Home />}></Route>
              <Route path="/home" element={<Home />}></Route>
              <Route path="/joinevent" element={<JoinEvent />}></Route>
              <Route path="/eventdetail" element={<EventDetail />}></Route>
              {/* <Route path="/hostevent" element={<HostEvent/>}></Route> */}
              <Route path="/jointraining" element={<JoinTraining />}></Route>
              <Route path="/jointeam" element={<JoinTeam />}></Route>
              <Route path="/charityrecord" element={<CharityRecord />}></Route>
              <Route path="/charitytokentransfer" element={<CharityTokenTransfer />}></Route>
              <Route path="/charitytokendonate" element={<CharityTokenDonate />}></Route>
              <Route path="/charitystory" element={<CharityStory />}></Route>
              <Route path="/charitytokengift" element={<CharityTokenGift />}></Route>
              <Route path="/charitytokengiftdetail" element={<CharityTokenGiftDetail />}></Route>
              <Route path="/servicemap" element={<ServiceMap />}></Route>
              {/* <Route path="/joblist" element={<JobList/>}></Route> */}
              <Route path="/article" element={<Article />}></Route>
              <Route path="/articledetail" element={<ArticleDetail />}></Route>
              <Route path="/userinfo" element={<UserInfo />}></Route>
              <Route path="/edituserinfo" element={<EditUserInfo />}></Route>
              <Route path="/aboutus" element={<AboutUs />}></Route>
              {/* <Route path="/shareqrcode" element={<ShareQRcode/>}></Route> */}
              <Route path="/myfamily" element={<MyFamily />}></Route>
              <Route path="/eventrecord" element={<EventRecord />}></Route>
              <Route path="/passbookrecord" element={<PassBookRecord />}></Route>
              <Route path="/giftrecord" element={<GiftRecord />}></Route>
              <Route path="/qrcodereader" element={<QRCodeReader />}></Route>

              <Route path="/login" element={<Login />}></Route>

            </Routes>
          </div>
          <Footer />
        </div>

      </div>

    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
