
import moment from 'moment'
import axios from 'axios';
import { useDispatch } from 'react-redux';



export const useGlobalFunc = () => {
  const dispatch = useDispatch();

  const setLoading = async (status) => {
    if (status == true) {
      dispatch({
        type: "UPDATE_LOADING",
        payload: { loading: true }
      });
    }
    else {
      dispatch({
        type: "UPDATE_LOADING",
        payload: { loading: false }
      });
    }

  };
  return { setLoading }

}

export let gUserInfo = {

}

export let gActiveType = [
  {
    id: 1,
    title: '圈內動態',
    value: '圈內動態',
    label: '圈內動態'
  },
  {
    id: 2,
    title: '全國動態',
    value: '全國動態',
    label: '全國動態'
  },
]

export let gEventArea = [
  {
    id: 1,
    title: '台北市',
    value: '台北市',
    label: '台北市'
  },
  {
    id: 2,
    title: '新北市',
    value: '新北市',
    label: '新北市'
  },
  {
    id: 3,
    title: '桃園市',
    value: '桃園市',
    label: '桃園市'
  },
  {
    id: 4,
    title: '新竹縣市',
    value: '新竹縣市',
    label: '新竹縣市'
  },
  {
    id: 5,
    title: '台中',
    value: '台中',
    label: '台中'
  },
  {
    id: 6,
    title: '台南',
    value: '台南',
    label: '台南'
  },
  {
    id: 7,
    title: '高雄',
    value: '高雄',
    label: '高雄'
  },
  {
    id: 8,
    title: '花蓮',
    value: '花蓮',
    label: '花蓮'
  },
  {
    id: 9,
    title: '台東',
    value: '台東',
    label: '台東'
  },
  {
    id: 10,
    title: '外島',
    value: '外島',
    label: '外島'
  },
]

export let gEventType = [
  {
    id: 1,
    title: '社區服務',
    value: '社區服務',
    label: '社區服務'
  },
  {
    id: 2,
    title: '文教服務',
    value: '文教服務',
    label: '文教服務'
  },
  {
    id: 3,
    title: '流浪犬照顧',
    value: '流浪犬照顧',
    label: '流浪犬照顧'
  },
  {
    id: 3,
    title: '淨灘社福',
    value: '淨灘社福',
    label: '淨灘社福'
  },
  {
    id: 4,
    title: '志工',
    value: '志工',
    label: '志工'
  },

]


export const menuData = [
  {
    title: "登入",
    path: "/login",
    scrollId: ""
  },
  {
    title: "首頁",
    path: "/joinevent",
    scrollId: ""
  },
]

export const defaultImg = require('../assets/default_icon.png')

export const ErrorImg = e => {
  e.target.onerror = null;
  e.target.src = require('../assets/default_icon.png')
};


export function timestamp2date(timestamp) {
  return moment(timestamp * 1000).format('YYYY/MM/DD HH:mm')
}

export function timestamp2date2(timestamp) {
  return moment(timestamp * 1000).format('YYYY/MM/DD')
}

export async function getUserInfoById(idToken) {
  console.log(idToken);
  let json = {
    token: idToken
  };

  let api_url = process.env.REACT_APP_API_URL + "/user/get";
  try {
    const response = await axios.post(api_url, json);

    return response.data.data.userInfo

  } catch (error) {
    console.error(error);
  }
}

export async function getUserInfo(idToken) {
  console.log(idToken);
  let params = {
    accessToken: localStorage.getItem('accessToken')
  }

  let api_url = process.env.REACT_APP_API_URL + "/front/user";
  try {
    const response = await axios.get(api_url, { params: params });
    console.log(response.data.data.user);

    //gUserInfo = response.data.data.userInfo

  } catch (error) {
    console.error(error);
  }
}

export async function getEventType() {
  console.log(gEventType.length)
  if (gEventType.length != 0) {
    return gEventType
  }
  let json = {
  };
  let api_url = process.env.REACT_APP_API_URL + "/activityType/get";
  try {
    const response = await axios.post(api_url, json);
    console.log(response.data.data);


    gEventType = response.data.data
    return gEventType

  } catch (error) {
    console.error(error);
  }
}


